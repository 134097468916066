@use 'sass:math';
@import "styles/_variables.scss";

.header {
    font-family: $font-family-default;
    color: $color-input-txt;
    font-weight: $font-weight-medium;
    font-size: $font-size-2xl;
    line-height: 28px;
    margin-bottom: $margin-s;
    display:flex;
    align-self:flex-start;
}

.txt_container {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    height: 16px;
    font-weight: $font-weight-regular;
    font-size: $font-size-xs;
    line-height: 16px;
    color: $color-input-txt;
    margin: $margin-xs 0 $margin-xs;
}

.txt_orange {
    color: $color-cta-orange;
    margin-left: $margin-2xs;
    width: 250px;
    word-wrap: break-word;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  width: 540px;
  max-width: 50vw;
  min-width: 200px;
}

//inputs container
.red {
  color: $color-error;
}

.btn_separator {
  margin-top: $margin-xl;
}

// show hide
.password_wrapper {
  position: relative;
}

.show_password {
  position: absolute;
  right: 160px;
  top: 43px;
  cursor: pointer;
}

.txt_show {
  font-size: 14px;
  line-height: 16px;
  color: $color-input-label;
}

//SettingsProfile
.subtitle {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-label;
}

.column {
  display: flex;
  flex-direction: column;
}

.title1 {
  font-weight: $font-weight-medium;
  font-size: $font-size-m;
  line-height: 21px;
  color: $color-input-txt;
  margin-top: math.div($margin-xl, 2);
  margin-bottom: $margin-xs;
}

.separator {
  border-top: 1px solid $color-grey-600;
  width: 100%;
  margin: math.div($margin-xl, 2) 0;
}

.current {
  font-weight: $font-weight-medium;
  font-size: $font-size-s;
  line-height: 18px;
  color: $color-input-txt;
  margin: math.div($margin-xl, 2) 0 $margin-xs 0;
}

.txt_container1 {
  @extend .txt_container;
  margin-bottom: math.div($margin-2xl, 2);
}

//preferences
.btn_delete {
  display: flex;
  align-self: start;
  margin-top: $margin-l;
}

.dropdown {
  width: 392px;
  margin-bottom: math.div($margin-xl, 2);
}

.separator1 {
  @extend .separator;
  margin: $margin-2xl 0;
}

.txt_delete{
  width: 614px;
  font-size: $font-size-xs;
  line-height: 22px;
  word-wrap: break-word;
  margin-top: $margin-l;
  color: $color-input-txt;
}

