.wrapper {
  display: flex;
  margin: 10px 0;
  padding: 10px;
  cursor: pointer;
}

.icon {
  margin-right: 15px;
}
