@use 'sass:math';
@import "styles/_variables.scss";

.task_container {
  box-sizing: border-box;
  border-radius: 6px;
}

.task_items {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $color-grey-600;
  padding: $padding-s 0px;

  &:last-child {
    border-bottom: none;
    margin: 0;
  }
}

.bordered {
  border: 1px solid $color-grey-600;
  border-radius: 6px;
  margin-bottom: math.div($margin-s, 2);

  &:last-child {
    border-bottom: 1px solid $color-grey-600;
  }
}

.task_container :hover {
  background: $color-grey-200;
  cursor: pointer;
}

.left {
  width: 100%;
  display: flex;
  align-items: center;
}

.title {
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-label;
  font-weight: $font-weight-regular;
  margin: $margin-xs $margin-s 0;
}

.title_open {
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-error;
  font-weight: $font-weight-regular;
  margin: $margin-xs $margin-s 0;
}

.title_ready {
  display: flex;
  align-items: center;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-gray-40;
  font-weight: $font-weight-regular;
  margin: $margin-xs $margin-s 0;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 $margin-s;
}

.new {
  background: $color-info;
  border-radius: 6px;
  padding: 6px 16px;
  font-size: $font-size-2xs;
  line-height: 14px;
  color: $color-white;

  //&:hover {
  //  background: $color-gray-40;
  //}
}

.open {
  background: $color-error;
  border-radius: 6px;
  padding: 6px 16px;
  font-size: $font-size-2xs;
  line-height: 14px;
  color: $color-white;

  //&:hover {
  //  background: $color-gray-40;
  //}
}

.ready {
  background: $color-cta-exLight-bl;
  border-radius: 6px;
  color: $color-info;
  padding: 6px 16px;
  font-size: $font-size-2xs;
  line-height: 14px;
  text-align: center;
}

.late {
  background: darken($color-cta-light-orange, 3%);
  border-radius: 6px;
  color: $color-cta-orange;
  padding: 6px 16px;
  font-size: $font-size-2xs;
  line-height: 14px;
  text-align: center;
}

.unready {
  background: $color-cta-light-rd;
  border-radius: 6px;
  color: $color-error;
  padding: 6px 16px;
  font-size: $font-size-2xs;
  line-height: 14px;
}

.neutral {
  background: $color-touched;
  border-radius: 6px;
  padding: 6px 16px;
  font-size: $font-size-2xs;
  line-height: 14px;
  color: $color-white;

  //&:hover {
  //  background: $color-gray-40;
  //}
}

.details_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.description_tooltip {
  display: none;
  position: absolute;
  bottom: 10px;
  left: 15px;
  max-width: 400px;
  padding: $padding-2xs;
  background: rgba($color-cta-orange, 0.6);
  border-radius: 4px;

  &:hover {
    background: rgba($color-cta-orange, 0.6);
  }
}

.subtitle {
  width: 80%;
  position: relative;
  font-size: $font-size-s;
  line-height: 16px;
  color: $color-input-label;
  font-weight: $font-weight-medium;
  margin: $margin-xs $margin-s;

  &:hover .description_tooltip {
    display: flex;
  }
}

.path {
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-label;
  margin: $margin-xs $margin-2xs $margin-xs $margin-s;

  .asset_name {
    font-size: $font-size-m;
    font-weight: $font-weight-medium;
    line-height: 24px;
    color: $color-input-label;
    margin: $margin-2xs 0;
    cursor: pointer;

    &:hover {
      color: rgba($color-cta-bl-dark, 0.6);
    }
  }
}

.created {
  width: 100%;
  display: flex;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-gray-40;
  margin: $margin-2xs 0 $margin-xs $margin-s;
}

.recurrence {
  margin-left: $margin-xs;
  font-size: $font-size-xs;
  line-height: 14px;
  color: $color-gray-40;
}

.date {
  margin: 0 $margin-s;
  font-size: $font-size-2xs;
  line-height: 14px;
  color: $color-gray-40;
}
