@import "styles/_variables.scss";

.header_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: $margin-xs 0 $margin-l 0;
}

.title {
  font-weight: $font-weight-medium;
  font-size: $font-size-2xl;
  line-height: 28px;
  color: $color-input-txt;
}

.row {
  display: flex;
  justify-content: row;
  height: 100vh;
  margin-top: $margin-xl;
}

.filter_section {
  @extend .header_section;
  margin-top: $margin-l;
}

.template_label {
  font-weight: $font-weight-medium;
}

.prototypes_container {
  margin-top: $margin-l;
}
