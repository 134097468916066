@import "styles/_variables.scss";

.modal {
  min-width: 550px;
}

.isLoading {
  margin-top: 50px;
}

.info {
  margin-top: 150px;
  font-size: $font-size-xs;
}

.info2 {
  margin-top: $margin-2xs;
  font-size: $font-size-xs;
}

.sectionWrapper {
  padding: $padding-s;
  border: 1px solid $color-grey-600;
  border-radius: 8px;
  margin-bottom: $margin-m;
}

.template_info {
  font-size: $font-size-xs;
  margin-bottom: $margin-m;
}

.import_info {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

.info_positive {
  display: block;
  color: $color-info;
  padding: 10px 0;
}

.info_negative {
  display: block;
  color: $color-error;
  padding: 10px 0;
}

.info_file_link {
  display: block;
  color: $color-gray-150;
  padding: 10px 0;
}

.margin_bottom {
  margin-bottom: $margin-l;
}
