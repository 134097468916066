@use 'sass:math';
@import "styles/_variables.scss";

.main_table {
  display: flex;
  flex-direction: row;
  border: 1px solid $color-grey-600;
  width: 100%;
  border-radius: 6px;
  margin-bottom: math.div($margin-xl, 2);
}

.title {
  flex: 1;
  display: flex;
  justify-content: space-between;
  font-size: $font-size-s;
  line-height: 16px;
  padding: $padding-s;
  color: $color-input-txt;
  font-weight: $font-weight-regular;
  border-bottom: 1px solid $color-grey-600;
}

.table_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid $color-grey-600;
  padding: $padding-s;
  font-weight: $font-weight-medium;
  height: 50px;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
}

.icon_trash {
  width: 11px;
  height: 14px;
}

.table1 {
  flex-grow: 1;
  border-right: 1px solid $color-grey-600;
}

.table1 :hover,
.table2 :hover {
  background: $color-grey-200;
  cursor: pointer;
}

.table2 {
  flex-grow: 1;
}

.table3 {
  flex-grow: 1;
  border-left: 1px solid $color-grey-600;
}

.title:last-child {
  border: none;
}
