@use 'sass:math';
@import "styles/_variables.scss";

.property_delete_confirmation {
  margin-left: $margin-m;
  display: flex;
}

.confirmation_text {
  font-size: $font-size-xs;
  font-weight: 400;
  color: $color-error;
}

.confirmation_button {
  margin-left: $margin-m;
  font-size: $font-size-xs;
  color: $color-txt;
  cursor: pointer;
}
