@use 'sass:math';
@import "styles/_variables.scss";

.modal {
  flex-basis: 1000px;
}

.red {
  color: $color-error;
}

.row {
  display: flex;
  flex-direction: row;
}

.group_checkbox {
  margin-bottom: $margin-l;
}

.dropdown {
  margin-bottom: $margin-xl;
}

.margin_top {
  margin-top: $margin-s;
  margin-bottom: $margin-m;
}

.margin_bottom {
  margin-bottom: $margin-s;
}

.start_row {
  @extend .row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.separator {
  border: 0.5px solid $color-grey-600;
}

.button_section {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: $margin-l $margin-xl $margin-l 0;
}

.btn_separator {
  margin-left: math.div($margin-xl, 2);
}

.propertyEditedWarning {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: $margin-xl;
    font-size: 14px;
    color: $color-error;

    button {
        margin-right: $margin-s;
    }
}

.optionIconWrapper {
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
}

