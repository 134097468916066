@use 'sass:math';
@import "styles/_variables.scss";

.dropdown {
  margin-bottom: 40px;
}

.red {
  color: $color-error;
}

.button_section {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: $margin-l $margin-xl $margin-l 0;
}

.btn_separator {
  margin-left: math.div($margin-xl, 2);
}