@use 'sass:math';
@import "styles/_variables.scss";

.row {
  display: flex;
  padding-bottom: math.div($padding-m, 2);
  align-items: center;
}

.drag_handle {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-left: $margin-s;

  .drag_handle_bar {
    width: 20px;
    height: 5px;
    border: 2px solid $color-border-input;
    border-radius: 2px;
    margin: 1px 0;
  }
}

.single_input {
  border: 1px solid $color-grey-800;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
  padding-left: $padding-xs;
  font-weight: $font-weight-regular;
  flex-basis: 392px;
  height: 38px;
  outline: none;
}

.single_input:focus {
  border: 1px solid $color-cta-orange;
}

.error {
  color: $color-error;
  font-size: $font-size-2xs;
  line-height: 13px;
  font-weight: $font-weight-regular;
  margin-top: $margin-xs;
}

.checkmark {
  top: -10px;
}

.delete_icon {
  margin-left: 12px;
  cursor: pointer;
}

.no_dropdown {
  margin-right: $margin-s;
}
