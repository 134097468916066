@use 'sass:math';
@import "styles/_variables.scss";

.box_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid $color-grey-600;
  justify-content: space-between;
  border-radius: 6px;
  margin: 8px 0;
}

.company_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: 300px;
  justify-content: space-between;
  border-radius: 6px;
}

.container {
  display: flex;
}

.txt_name {
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
  flex-basis: 250px;
  word-break: break-word;
}

.txt_bool {
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-label;
  min-width: 200px;
}

.no_img {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-grey-60;
  width: 30px;
  height: 30px;
  font-weight: $font-weight-medium;
  font-size: $font-size-xs;
  border-radius: 50%;
  line-height: 16px;
  color: $color-white;
  padding: math.div($padding-m, 2);
  margin: math.div($margin-m, 2);
}

.meatball {
  margin-right: $margin-s;
}
