@import "../../styles/_variables.scss";

.property_selector {
  width: fit-content;
  padding: $padding-xs;
  border: 1px solid $color-grey-600;
  border-radius: 4px;
  background: $color-white;
  font-size: $font-size-xs;
  font-weight: $font-weight-regular;
  color: $color-input-txt;
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label {
  font-size: $font-size-2xs;
  line-height: 15px;
  letter-spacing: 0.025em;
  margin: $margin-xs;
  height: 16px;
  color: $color-input-txt;
}

.delete_btn {
  cursor: pointer;
}
