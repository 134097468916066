@use 'sass:math';
@import "styles/_variables.scss";

// uploaded
.input_box {
  border: 1px solid $color-grey-60;
  border-radius: 6px;
  padding: math.div($padding-xl, 2);
  width: 182px;
  height: 90px;
  word-wrap: break-word;
  margin: 0 math.div($margin-m, 2) math.div($margin-xl, 2) 0;
  &:hover {
    background: $hovered-upload;
  }
}

.input_box2 {
  border: 1px solid $color-grey-60;
  border-radius: 6px;
  padding: math.div($padding-xl, 2);
  width: 182px;
  height: 90px;
  word-wrap: break-word;
  margin: 0 math.div($margin-m, 2) math.div($margin-2xl, 2) 0;
  background: $color-grey-700;
  opacity: 0.6;
}

.input {
  margin-top: $margin-xs;
  display: flex;
  align-items: center;
}

.icon {
  width: 13px;
  height: 19px;
  cursor: pointer;

}

input[type="file"] {
  display: none;
}

.input_name {
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  width: 150px;
  white-space: nowrap;
  font-weight: $font-weight-medium;
}

.input_size {
  font-size: $font-size-2xs;
  line-height: 14px;
  color: $color-gray-40;
  margin-left: $margin-2xs;
}

.trash_white {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  margin-top: $margin-xs;
}

.trash_icon {
  position: absolute;
  cursor: pointer;
}

.view_icon {
  position: absolute;
  bottom: -5px;
  right: 45px;
  cursor: pointer;
}

.download_icon {
  position: absolute;
  bottom: -5px;
  right: 25px;
  cursor: pointer;
}
