@import "styles/_variables.scss";

.content {
  padding: 0 $padding-xl $padding-l $padding-xl;
  min-height: 42vh;
  max-height: calc(100vh - (100px + 65px + 64px));
  padding-bottom: 200px;
  //padding-bottom: 50px;
  overflow-x: auto;
}

.contentS {
  padding: 0 $padding-xl $padding-l $padding-xl;
  min-height: 18vh;
  max-height: calc(100vh - (100px + 65px + 64px));
  padding-bottom: 270px;
  //padding-bottom: 50px;
  overflow-x: auto;
}

.contentNoPadding {
  padding: 0 $padding-xl $padding-l $padding-xl;
  min-height: 18vh;
  max-height: calc(100vh - (100px + 65px + 64px));
  padding-bottom: 50px;
  overflow-x: auto;
}

.contentZeroPadding {
  padding: 0 $padding-xl 0 $padding-xl;
  min-height: 18vh;
  max-height: calc(100vh - (100px + 65px + 64px));
  overflow-x: auto;
}
