@import "styles/_variables.scss";

.container {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  border: 1px solid $color-grey-600;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
}

.selected {
  background-color: $color-cta-orange;
  color: #fff;
}

.selectedItem {
  background-color: rgb(217, 250, 239);
  color: #000;
}
