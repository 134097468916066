@import "styles/_variables.scss";

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid $color-border-input;
  box-shadow: $shadow-input;
  margin-bottom: $margin-2xs;
}

.open {
  background: $color-grey-20;
}

.closed {
  background: $color-white;
}
