@import "../../styles/_variables.scss";

.rules_editor {
  background: $color-white;
  padding: $padding-xs;
  margin-top: $margin-s;
  margin-bottom: $margin-s;
  border: 1px solid $color-border-input;
  border-radius: 5px;
}

.add_rule_btn {
  font-size: $font-size-2xs;
  line-height: 14px;
  color: $color-cta-orange;
  margin-bottom: $margin-s;
  cursor: pointer;
}
