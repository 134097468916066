@use 'sass:math';
@import "styles/_variables.scss";

.width {
  width: 392px;
}

.red {
  color: $color-error;
}

.modal {
  flex-basis: 876px;
}

.start_row {
  display: flex;
  flex-wrap: wrap;
}

.add_txt {
  font-size: $font-size-s;
  line-height: 18px;
  color: $color-cta-orange;
}

.button_section {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: $margin-l $margin-xl $margin-l 0;
}

.btn_separator {
  margin-left: math.div($margin-xl, 2);
}

.layoutImageWrapper {
  position: relative;
  width: 200px;
  margin-bottom: $margin-xs;
  border: 1px solid $color-border-input;
}

.layoutImagePreview {
  width: 200px;
  height: auto;
}

.layoutImageDeleteButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.separator {
  height: 40px;
}

@media (min-width: 767px) {
  .modal {
    min-width: 700px;
  }
}

@media (min-width: 340px) {
  .modal {
    min-width: 300px;
    margin-left: 50px;
  }
  .width {
    min-width: 300px;
  }
}
