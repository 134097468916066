@use 'sass:math';
@import "styles/_variables.scss";

.table_row {
    display:flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    font-weight: $font-weight-medium;
    font-size: $font-size-xs;
    height: 50px;
    border-top: 1px solid $color-grey-600;
}

.table_left {
    width: 22%;
    display: flex;
    align-items: center;
    padding-left: $padding-3xl;
    border-right: 1px solid $color-grey-600;
    background: $color-cta-light-bl;
}

.table_right {
    width: 78%;
    display: flex;
    align-items: center;
    padding-left: $padding-l;
  
}