@import "styles/_variables.scss";

.menu_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.menu_trigger {
  border: none;
  cursor: pointer;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.disabled_placeholder {
  width: 15px;
}

.menu {
  background: $color-white;
  border: 1px solid $color-grey-600;
  z-index: 1;
  border-radius: 6px;
  position: absolute;
  top: 25px;
  right: -25px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(0);
}

.menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.menu :hover {
  background: $color-grey-200;
}

.item_container {
  display: flex;
  align-items: center;
  padding: $padding-s;
  cursor: pointer;
}

.icon_menu {
  width: 12px;
  height: 12px;
}

.label_menu {
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
  margin-left: $margin-xs;
}
