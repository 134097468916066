@use 'sass:math';
@import "styles/_variables.scss";

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-wrap: wrap;
}

.picker_wrapper {
  display: inline;
  width: fit-content;
  cursor: pointer;
}

:global(.react-datepicker__input-container) input {
  text-align: start;
  background: $color-white;
  border: 1px solid $color-grey-600;
  border-radius: 6px;
  padding: $padding-xs;
  margin-right: math.div($margin-m, 2);
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
  width: 190px;
  &:focus {
    outline: none;
    border: 1px solid $color-cta-orange;
  }
}

:global(.react-datepicker__month-container) {
  border: 1px solid $color-cta-orange;
}

:global(.react-datepicker__input-container) input[readOnly] {
  background: $color-grey-500;
  opacity:0.5;
  border: 1px sold $color-grey-600;
}

:global(.react-datepicker__day--selected) {
  background: $color-cta-orange;
  border-radius: 50%;
}

:global(.react-datepicker__header) {
  background: $color-white;
  border: none;
}

:global(.react-datepicker__triangle) {
  display: none;
}

.icon {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 160px;
  top: 8px;
}
