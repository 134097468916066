@use 'sass:math';
@import "styles/_variables.scss";

.modal {
  position: relative;
  flex-basis: 876px;
}

.title {
  font-weight: $font-weight-medium;
  font-size: $font-size-s;
  line-height: 18px;
  color: $color-input-txt;
}

.asset_info {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: math.div($padding-m, 2);
  margin-right: 40px;

  .info_item {
    display: flex;
    align-items: center;
    font-size: $font-size-xs;
    color: $color-input-label;
    font-weight: $font-weight-medium;

    .asset_path {
      width: fit-content;
      white-space: nowrap;
      margin-right: 5px;
    }

    .asset_name {
      font-size: $font-size-m;
      font-weight: $font-weight-medium;
      line-height: 24px;
      color: $color-input-label;
      margin: $margin-2xs 0;
      cursor: pointer;

      &:hover {
        color: rgba($color-cta-bl-dark, 0.6);
      }
    }
  }
}

.row {
  width: 100%;
  display: flex;
  margin-top: 10px;

  .column {
    width: 50%;
  }
}

.date {
  height: 30px;
  display: flex;
  align-items: center;
}

.btn_section {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: $margin-l $margin-xl $margin-l 0;
}

.btn_separator {
margin-left: math.div($margin-xl, 2);
}

@media (max-width: 767px) {
  .modal {
    min-width: 700px;
  }
}

@media (min-width: 340px) {
  .modal {
    min-width: 300px;
    margin-left: $margin-3xl;
  }
}
