@use 'sass:math';
@import "styles/_variables.scss";

.column {
  display: flex;
  flex-direction: column;
}

.filters_section {
  margin-top: $margin-m;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row {
  display: flex;
  flex-direction: row;
  padding-top: $padding-xs;
}

.red {
  color: $color-error;
}

.container_item {
  margin-bottom: $margin-xs;
}

.accordion_header {
  background: $color-white;
  padding: math.div($padding-m, 2);
}

.accordion_panel {
  display: flex;
  flex-direction: column;
  padding-left: ($padding-l * 2);
  background-color: $color-white;
  padding-right: math.div($padding-xl, 2);
}

.visible_for_sub_companies {

  .accordion_header {
    background: $color-cta-light-orange;
  }

  .accordion_panel {
    background: $color-cta-light-orange;
  }
}
