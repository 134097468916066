@import "styles/_variables.scss";

.sidebar {
  height: 100vh;
  width: 72px;
  z-index: 1;
  background: $color-input-txt;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.icon_holder,
.sidebar a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 8px;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
}

.icon_holder:hover,
.sidebar a:hover {
  background: $color-grey-100;
}

.label_sidebar {
  font-weight: $font-weight-medium;
  font-size: $font-size-3xs;
  margin-top: $margin-xs;
  line-height: 11px;
  text-align: center;
  color: $color-grey-600;
}

.icon_sidebar {
  height: 25px;
  width: 25px;
}

.selected_link {
  background: $color-grey-100;
}

.button {
  margin: 0 5px 20px 5px;
  height: 30px;
  background-color: $color-touched;
  display: flex;
  font-size: $font-size-3xs;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-height: 665px) {
  .sidebar a {
    padding: 4px 8px;
  }

  .icon_sidebar {
    height: 15px;
    width: 15px;
  }
}
