@import "../../styles/_variables.scss";

.requirements_editor {
  background: $color-white;
  border: 1px solid $color-border-input;
  padding: $padding-xs;
  margin-bottom: $margin-s;
  border-radius: 5px;
}

.requirements_form {
  background: $color-white;
  border: 1px solid $color-border-input;
  padding: $padding-xs;
  margin-bottom: $margin-s;
  border-radius: 5px;
}

.requirements_form_new {
  @extend .requirements_form;
  background: $color-cta-light-rd;
}

.or_separator {
  padding-left: $padding-xs;
  margin-bottom: $margin-s;
  color: $color-input-txt;
}

.add_requirement_button {
  font-size: $font-size-2xs;
  line-height: 14px;
  color: $color-cta-orange;
  margin-bottom: $margin-s;
  cursor: pointer;
}

.input_label {
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-2xs;
  line-height: 16px;
  padding: $padding-xs;
  color: $color-input-txt;
}

.input_label_checkbox {
  @extend .input_label;
  cursor: pointer;
}

.checkbox {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  background-color: $color-white;
  border: 1px solid $color-border-input;
  padding: 9px;
  border-radius: 8px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.checkbox:checked {
  background-color: $color-white;
  border: 2px solid $color-cta-orange;
  color: #99a1a7;
}

.checkbox:checked:after {
  content: '\2714';
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 3px;
  color: $color-cta-orange;
}

.checkbox:disabled {
  background-color: $color-grey-20;
  cursor: default;
}

.row {
  display: flex;
  flex-direction: column;
  margin-bottom: $margin-s;
}

.row_no_bottom_margin {
  @extend .row;
  margin-bottom: 0;
}

.row_vertical {
  @extend .row;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.row_vertical_no_bottom_margin {
  @extend .row_vertical;
  margin-bottom: 0;
}

.row_conditions_and {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: $font-size-xs;
  line-height: 16px;
  padding: 0 $padding-s $padding-xs;
  color: $color-input-txt;
}
