@use 'sass:math';
@import 'styles/_variables.scss';

.row {
    display: flex;
    flex-direction: row;
    margin-top: $margin-m;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.column {
    display: flex;
    flex-direction: column;
}

.txt {
    font-size: $font-size-xs;
    line-height: 16px;
    color: $color-input-txt;
    margin-top: $margin-l;
}

.txt1 {
    font-size: $font-size-xs;
    line-height: 16px;
    color: $color-input-txt;
}

.txt_gray {
    font-size: $font-size-xs;
    line-height: 16px;
    color: $color-gray-40;
    margin-bottom: $margin-2xs;
}

.btn_section {
    display: flex;
    justify-content: flex-end;
    margin: 0 $margin-xl $margin-l 0;
}

.modal {
    height: 370px;
}

.icon {
    margin-right: 20px;
}