@import "styles/_variables.scss";

.input_search {
  margin-bottom: $margin-m;
  width: 392px;
}

.divider {
  border-top: 1px solid $color-grey-600;
  width: 392px;
  margin: 20px 0;
}

.column {
  display: flex;
  flex-direction: column;
}

.whatsNew {
  color: $color-grey-800;
  margin: 10px 0;
}

.adminGuideWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 392px;
  align-items: center;
  background-color: $color-grey-20;
}

.adminHeader {
  font-weight: 700;
  font-size: $font-size-m;
  margin-bottom: $margin-s;
  margin-top: $margin-s;
}

.buttonWrapper {
  margin: 25px 0;
}

.footWrapper {
  width: 392px;
  display: flex;
  justify-content: space-around;
  color: $color-input-label;
}
