@use 'sass:math';
@import "styles/_variables.scss";

.container {
  //max-width: 200px;
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  justify-self: center;
  padding-bottom: 5px;
}

.label {
  padding-bottom: math.div($padding-xl, 2);
  position: relative;
  padding-left: $padding-l;
  user-select: none;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
  font-weight: $font-weight-regular;
  cursor: pointer;
}

.label_single {
  position: relative;
  padding-left: $padding-l;
  user-select: none;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
  font-weight: $font-weight-regular;
  cursor: pointer;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: -2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: $color-white;
  border-radius: 8px;
  border: 1.3px solid $color-grey-600;
}

.container:hover input ~ .checkmark {
  background: $color-grey-600;
}

.container input:checked ~ .checkmark {
  background: $color-white;
  border: 2px solid $color-touched;
}

.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark::after {
  display: block;
}

.container .checkmark::after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: 1px solid $color-touched;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

//disabled
.checkmark2 {
  position: absolute;
  top: -2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: $color-white;
  border-radius: 8px;
  border: 1.3px solid $color-grey-700;
}

.container:hover input ~ .checkmark2 {
  background: $color-grey-700;
}

.container input:checked ~ .checkmark2 {
  background: $color-white;
  border: 2px solid $color-grey-700;
}

.checkmark2::after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark2::after {
  display: block;
}

.container .checkmark2::after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: 1px solid $color-grey-700;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.container_vertical {
  @extend .container;
  max-width: unset;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $margin-s;

  .label {
    padding-bottom: $padding-xs;
  }
}
