@use 'sass:math';
@import "styles/_variables.scss";

.modal {
  border: 1px solid $color-cta-orange;
  border-radius: 6px;
}

.width_dropdown {
  width: 190px;
  margin-right: math.div($margin-m, 2);
}

.width_search {
  width: 390px;
}

.row {
  display: flex;
  flex-direction: row;
}

.input_search {
  margin-bottom: $margin-m;
}

.divider {
  border: 0.5px solid $color-grey-600;
  margin: 0 0 $margin-xs 0;
}

.button_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $margin-l $margin-xl $margin-l $margin-xl;
}

.buttons_group {
  display: flex;
  flex-direction: row;
}

.select_all_buttons {
  @extend .buttons_group;
  margin-bottom: $margin-xs;
}

.btn_separator {
  margin-left: math.div($margin-xl, 2);
}
