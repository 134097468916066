@use 'sass:math';
@import "styles/_variables.scss";

.accordion_header {
  height: 40px;
  flex: auto;
}

.background_form {
  background: $color-cta-light-bl;
}

.property_name_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  margin-bottom: $margin-xs;
}

.displayed_property_selector {
  display: flex;
  align-items: center;
  height: 50px;
}

.displayed_property_selector_label {
  margin-right: $margin-xs;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
  font-weight: $font-weight-regular;
}

.delete_btn {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.delete {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.date_section {
  display: flex;
  justify-content: row;
  align-items: center;
}

.edit_icon {
  width: 15px;
  height: 15px;
  cursor: pointer;

  path {
    fill: $color-txt;
  }
}

.delete_icon {
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.red {
  color: $color-error;
}

.red_alone {
  margin-left: $margin-m + 2;
  margin-bottom: -$margin-s;
  font-size: $font-size-2xs;
  color: $color-error;
}

.width {
  margin-right: math.div($margin-m, 2);
}

.indicator {
  align-self: center;
  margin: 0 4px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  justify-self: center;
  padding-bottom: 5px;
}

.label {
  padding-bottom: math.div($padding-xl, 2);
  position: relative;
  padding-left: $padding-l;
  user-select: none;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
  font-weight: $font-weight-regular;
  cursor: pointer;
}

.label_single {
  position: relative;
  padding-left: $padding-l;
  user-select: none;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
  font-weight: $font-weight-regular;
  cursor: pointer;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.unit {
  margin-left: 10px;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
}

.edit_btn {
  margin-left: $margin-s;
  font-size: $font-size-xs;
  font-weight: 400;
  color: $color-txt;
  cursor: pointer;
}

.property_item {
  padding-bottom: $margin-xs;
  border-bottom: 1px solid $color-border-input;
  margin-bottom: $margin-xs;
}

.disabled {
  opacity: 0.7;

  input, label {
    cursor: default;
  }
}
