@use 'sass:math';
@import "styles/_variables.scss";

.sensorButtonWrapper {
  padding: 10px;
  display: flex;
  justify-content: flex-end;

  .space {
    margin-right: $margin-xs;
  }
}

.customTooltip {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  padding: 3px 20px;
}

.chartsWrapper {
  min-height: 300px;
  position: relative;
}

.activityWrapper {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
