@import "styles/_fonts";
@import "styles/_variables.scss";

*,
*::before,
*::after {
  font-family: $font-family-default;
  box-sizing: border-box;
}

html {
  width: 100vw;
  height: 100vh;
  font-size: $font-size-s;
  font-weight: $font-weight-regular;
  overflow: hidden;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
