@use 'sass:math';
@import "styles/_variables.scss";

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  padding-top: $padding-xs;
}

.container_item {
  margin-bottom: $margin-xs;
}

.org_email {
  margin-left: 12px;
}

.accordion_header {
  background: $color-white;
}

.accordion_panel {
  display: flex;
  flex-direction: column;
  padding-left: ($padding-l * 2);
  background-color: $color-white;
  padding-right: math.div($padding-xl, 2);
}

.filters_section {
  margin-top: $margin-m;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttonLeft {
  margin-right: $margin-s;
}
