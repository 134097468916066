@use 'sass:math';
@import "styles/_variables.scss";

.modal {
  flex-basis: 90%;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.btn_section {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: $margin-l $margin-xl $margin-l 0;
}

.btn_separator {
  margin-left: math.div($margin-xl, 2);
}

.buildingList {
  width: 500px;
  height: 300px;
  overflow: auto;
  padding: 20px;
  border: 1px solid $color-grey-600;
  border-radius: 8px;
}

.selectAll {
  padding: 10px;
  cursor: pointer;
}

.searchResults {
  margin-right: 20px;
  margin-bottom: 20px;
}
