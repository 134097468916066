@use 'sass:math';
@import "styles/_variables.scss";

.red {
  color: $color-error;
}

.row {
  display: flex;
  flex-direction: row;
}

.rowSpace {
  @extend .row;
  justify-content: space-between;
}

.dropdown {
  justify-content: center;
  width: 200px;
  margin: 0 math.div($margin-m, 2);
}

.conditionWrapper {
  padding: $padding-s;
  margin-bottom: $margin-s;
  margin-top: $margin-s;
  border-radius: 5px;
  background-color: rgba($color-cta-orange, $alpha: 0.1);
}

.delete_button {
  align-self: flex-start;
  cursor: pointer;
}

.button {
  margin-top: $margin-s;
}
