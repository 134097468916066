@import "styles/_variables.scss";

.sidebar {
  height: 100vh;
  width: 260px;
  z-index: 1;
  background: $color-cta-light-orange;
  padding-top: ($padding-l *2);
  padding-left: $padding-l;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.sidebar a {
  display: flex;
  padding-top: $padding-xl;
  flex-direction: column;
  text-decoration: none;
}

.label_sidebar {
  font-weight: $font-weight-medium;
  font-size: $font-size-s;
  line-height: 18px;
  color: $color-input-txt;
  &:hover {
    background: $color-grey-200;
    color: $color-touched;
  }
}

.title {
  font-weight: $font-weight-medium;
  font-size: $font-size-m;
  line-height: 21px;
  color: $color-input-txt;
}

.selected_link > span {
  color: $color-touched;
}