@import "../../styles/_variables.scss";

.condition {
  display: flex;
  align-items: center;
  font-size: $font-size-xs;
  font-weight: $font-weight-regular;
  color: $color-input-txt;
  margin-top: $margin-xs;
}

.property_value {
  margin-right: $margin-s;
}

.text_input {
  height: 38px;
  border: 1px solid $color-grey-600;
  border-radius: 8px;
  font-weight: $font-weight-regular;
  font-size: $font-size-xs;
  line-height: 16px;
  padding-left: $padding-s;
  color: $color-input-txt;
  outline: none;
}

.number_input {
  height: 38px;
  border: 1px solid $color-grey-600;
  border-radius: 8px;
  font-weight: $font-weight-regular;
  font-size: $font-size-xs;
  line-height: 16px;
  padding-left: $padding-s;
  margin-left: $margin-s;
  color: $color-input-txt;
  outline: none;
}

.add_btn {
  border: 1px solid $color-cta-orange;
  border-radius: 4px;
  display: flex;
  align-self: center;
  padding: 10px;
  background: $color-cta-orange;
  margin-left: $margin-s;
  margin-top: 1px;
  cursor: pointer;
}

.add_icon {
  width: 14px;
  height: 14px;
}
