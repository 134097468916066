@use 'sass:math';
@import "styles/_variables.scss";

.main_container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 2;
  height: auto;
}

.main_border {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid $color-grey-600;
  margin-top: $margin-xs;
  border-radius: 6px;
}

.contained_cell {
  //overflow: hidden;
  width: auto;
  background: $color-white;
  border: 1px solid $color-grey-600;
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: $margin-2xs;
}

.accordion_top {
  border-radius: 6px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 2;
  gap: $margin-xs;
}

.btn_container {
  display: flex;
  flex-direction: row;
}

.btn {
  padding: $padding-s;
  font-size: $font-size-s;
  line-height: 18px;
  font-weight: $font-weight-medium;
  text-align: center;
  color: $color-input-txt;
  border: none;
  background: $color-white;
  cursor: pointer;
}

.task_container {
  border-bottom: 1px solid $color-grey-600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: $padding-s;
}

.add_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-left: auto;
  cursor: pointer;
}

.task_filters_container {
  padding: $padding-2xs $padding-s;
  border-bottom: 1px solid $color-grey-600;
}

.tab {
  flex-basis: 200px;
}

.active {
  border-bottom: 3px solid $color-input-txt;
  font-weight: $font-weight-medium;
}

.inactive {
  font-weight: $font-weight-regular;
}

.addSensorButton {
  cursor: pointer;
}

.icon {
  width: 14px;
  height: 14px;
}

.charts_container {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

//right
.cell_right {
  position: relative;
  //display: flex;
  //align-items: flex-end;
  //justify-content: center;
  height: 200px;
  width: 100%;
  overflow: hidden;
  background: $color-white;
  border: 1px solid $color-grey-600;
  border-radius: 6px;
  margin-bottom: $margin-2xs;
}

.viewerScreen {
  position: absolute;
}

.main_right {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
}

.img {
  margin-right: $margin-s;
}

.accordion_header {
  height: 50px;
  border-radius: 6px;
  flex: auto;
}

.accordion_content {
  width: 100%;
  background: $color-white;
}

.filters {
  display: flex;
  padding: $padding-xs $margin-s;
  flex-wrap: wrap;
  gap:$margin-2xs;

  .sort {
    margin-right: $margin-s;
  }
}

.recurence_content {
  padding: 0 $padding-xs;
}

.sensorButtonWrapper {
  padding: 10px;
  display: flex;
  justify-content: flex-end;

  .edit {
    margin-right: $margin-xs;
  }
}
