@import "styles/_variables.scss";

.container {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  //flex: 1;
  height: 50px;
  align-items: center;
  justify-content: space-between;
}

.contentWrapper {
  display: flex;
  align-items: center;
  flex: 1;
}

.icon {
  display: flex;
  width: 50px;
  min-width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
}

.content {
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
}

.nameWrapper {
  display: flex;
  align-items: center;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
}

.txt {
  width: fit-content;
  font-size: $font-size-s;
  line-height: 20px;
  color: $color-light-black;
  font-weight: $font-weight-regular;
  margin-left: $margin-xs;
}

.txt_open {
  font-weight: $font-weight-medium;
}

.infoTooltip {
  display: none;
  position: absolute;
  width: 180px;
  bottom: 10px;
  left: 15px;
  padding: $padding-2xs;
  background: rgba($color-cta-orange, 0.6);
  border-radius: 4px;
  text-align: center;
}

.layout_button_wrapper {
  width: 40px;
  height: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover .infoTooltip {
    display: flex;
  }
}

.layout_indicator {
  width: 24px;
  height: 24px;
  cursor: default;
}

.layout_button {
  width: 24px;
  height: 24px;
}

.badge {
  display: flex;
  font-size: $font-size-2xs;
  margin: $margin-s $margin-2xl $margin-s $margin-s;
  background-color: $color-grey-20;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 25px;
  min-width: 25px;
  height: 25px;
  flex-grow: 0;
}

.badge_open {
  background: $color-white;
}

.badge_not_visible {
  background: transparent;
}

.divider {
  border-left: 1px solid $color-grey-600;
  height: 25px;
}

.isNotActive {
  text-decoration: line-through;
}

.notTopLevel {
  font-style: italic;
}

.inactive {
  color: $color-grey-60;
}

.alert {
  margin-left: 3px;
  font-size: 14px;
  color: $color-error;
}
