@import "styles/_variables.scss";

.header_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: $margin-xs 0 $margin-l 0;

  .title {
    font-weight: $font-weight-medium;
    font-size: $font-size-2xl;
    line-height: 28px;
    color: $color-input-txt;
  }
}

.reports_list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  margin-bottom: $margin-xl;
  gap: $margin-s
}

.report_item {
  width: 250px;
  min-width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: $padding-xs $padding-xs $padding-xs $padding-s;
  margin-bottom: $margin-s;
  border: 1px solid $color-gray-30;
  border-radius: 4px;

  .report_item_name {
    width: calc(100% - 55px);
  }

  .report_item_actions {
    width: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .action_button {
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .action_button_icon {
      width: 20px;
      height: 20px;
    }
  }
}

.report_preview {
  width: 100%;
  max-width: 100%;
  height: 60%;
  overflow: hidden;

  .report_table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
  }

  .report_table_row_header {
    background: $color-grey-500;
    font-size: 20px;
    text-align: center;
  }

  th {
    background: $color-grey-500;
    font-size: 20px;
    border: 1px solid $color-grey-700;
  }

  td {
    border: 1px solid $color-grey-700;
    padding: $padding-xs;
  }

  tr:nth-child(2) {
    font-weight: 500;
    background: $color-light-blue;
  }
}
