@use 'sass:math';
@import "styles/_variables.scss";

.task_list_container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: $padding-s 0px;
  &:last-child {
    margin: 0;
  }
}

.task_item {
  position: relative;
  width: 100%;
  display: flex;
  border: 1px solid $color-grey-600;
  border-radius: 6px;
  padding: $padding-s;
  margin-bottom: math.div($margin-s, 2);
}

.task_item_column {
  width: 50%;
}

.task_item_field_label {
  font-size: $font-size-xs;
  font-weight: 500;
  line-height: 14px;
  color: $color-input-label;
  margin-bottom: $margin-xs;
}

.task_item_field {
  font-size: $font-size-xs;
  line-height: 14px;
  color: $color-gray-40;
  margin-bottom: $margin-s;
}

.group_active {
  position: absolute;
  top: 15px;
  right: 80px;
  font-size: $font-size-xs;
  background: $color-info;
  color: $color-white;
  border: 1px solid $color-info;
  border-radius: 6px;
  padding: $padding-2xs;
}

.group_pending {
  position: absolute;
  top: 15px;
  right: 80px;
  font-size: $font-size-xs;
  background: $color-cta-orange;
  color: $color-white;
  border: 1px solid $color-cta-orange;
  border-radius: 6px;
  padding: $padding-2xs;
}

.group_finished {
  position: absolute;
  top: 15px;
  right: 80px;
  font-size: $font-size-xs;
  background: $color-grey-700;
  color: $color-white;
  border: 1px solid $color-grey-700;
  border-radius: 6px;
  padding: $padding-2xs;
}

.group_action_buttons {
  position: absolute;
  top: 15px;
  right: 10px;
  width: 55px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .group_edit_button,
  .group_delete_button {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .group_delete_button {
    margin-left: $margin-xs;
  }

  .action_button_icon {
    width: 18px !important;
    height: 18px !important;

    path {
      fill: $color-grey-800 !important;
    }

    &:hover {

      path {
        fill: $color-cta-orange !important;
      }
    }
  }
}
