@import "../../styles/_variables.scss";

.rule_item {
  background: $color-grey-20;
  padding: $padding-xs;
  margin-bottom: $margin-s;
  border: 1px solid $color-border-input;
  border-radius: 5px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: $font-weight-bold;
  font-size: $font-size-xs;
  line-height: 16px;
  padding-top: $margin-2xs;
  padding-bottom: $margin-2xs;
  color: $color-input-txt;
  cursor: pointer;
}

.local_label {
  margin-left: $margin-s;
  padding: $padding-2xs;
  border: 1px solid $color-cta-bl;
  background: lighten($color-cta-bl, 50%);
  border-radius: 5px;
}

.global_label {
  @extend .local_label;
  border-color: $color-cta-orange;
  background: lighten($color-cta-orange, 50%);
}

.disabled_label {
  @extend .local_label;
  border-color: $color-cta-rd;
  background: lighten($color-cta-rd, 50%);
}

.row {
  display: flex;
  flex-direction: column;
  margin-bottom: $margin-s;
}

.row_no_bottom_margin {
  @extend .row;
  margin-bottom: 0;
}

.row_vertical {
  @extend .row;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.row_vertical_no_bottom_margin {
  @extend .row_vertical;
  margin-bottom: 0;
}

.task_action_row {
  @extend .row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: $margin-xs;
  padding-bottom: $padding-xs;
  border-bottom: 1px solid $color-border-input;

  .task_action_group {
    display: flex;
    align-items: center;
  }

  .task_action_group_space_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.row_conditions_and {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: $font-size-xs;
  line-height: 16px;
  padding: 0 $padding-s $padding-xs;
  color: $color-input-txt;
}

.input_label {
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-2xs;
  line-height: 16px;
  padding: $padding-xs;
  color: $color-input-txt;
}

.input_label_regular {
  @extend .input_label;
  font-weight: $font-weight-regular;
  white-space: nowrap;
}

.input_label_checkbox {
  @extend .input_label;
  cursor: pointer;
}

.text_input {
  height: 38px;
  border: 1px solid $color-grey-600;
  border-radius: 8px;
  font-weight: $font-weight-regular;
  font-size: $font-size-xs;
  line-height: 16px;
  padding-left: $padding-s;
  color: $color-input-txt;
  outline: none;
}

.red {
  color: $color-error;
}

.error {
  padding: $padding-2xs;
  font-weight: $font-weight-regular;
  font-size: $font-size-2xs;
  line-height: 16px;
  color: $color-error;
}

.text_input_margin {
  @extend .text_input;
  margin-right: $margin-xs;
}

.delete_task_action_btn {
  margin-left: $margin-s;
  cursor: pointer;
}

.disable_rule_action_btn {
  margin-left: $margin-s;
  font-weight: $font-weight-regular;
  color: $color-txt;
  cursor: pointer;
}

.checkbox {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  background-color: $color-white;
  border: 1px solid $color-border-input;
  padding: 9px;
  border-radius: 8px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.checkbox:checked {
  background-color: $color-white;
  border: 2px solid $color-cta-orange;
  color: #99a1a7;
}

.checkbox:checked:after {
  content: '\2714';
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 3px;
  color: $color-cta-orange;
}

.checkbox:disabled {
  background-color: $color-grey-20;
  cursor: default;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.buttons_right {
  @extend .buttons;
  justify-content: flex-end;
}

.notification_groups_selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: $margin-s;
  margin-bottom: $margin-m;
}
