@import "styles/_variables.scss";

.input_container {
  display: flex;
  flex-direction: column;
  min-height: 100px;
}

.input_container_no_fixed_height {
  display: flex;
  flex-direction: column;
  min-height: unset !important;
}
