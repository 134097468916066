@import "styles/_variables.scss";

.main_container {
  &:hover {
    background: $color-grey-200;
  }
}

.chart_title {
  margin: $margin-s;
  padding-left: $padding-l;
  display: flex;
  justify-content: space-between;
}

.chart_content {
  // border-bottom: 1px solid $color-grey-600;
  display: flex;
  flex-direction: row;
  padding-bottom: $padding-l;
}

@media (max-width: 767px) {
  .chart_content {
    flex-direction: column;
  }
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.results {
  display: flex;
  flex-direction: column;
  margin: $margin-s;
  border-radius: 6px;
  width: 243px;
  padding: $padding-xs 0 $padding-xs $padding-s;
}

.results_0 {
  @extend .results;
  border: 1px solid #01b6cb;
}

.results_1 {
  @extend .results;
  border: 1px solid #64b90c;
}

.results_2 {
  @extend .results;
  border: 1px solid #d2042d;
}

.results_3 {
  @extend .results;
  border: 1px solid #2264d1;
}

.results_4 {
  @extend .results;
  border: 1px solid #f9dc76;
}

.avg {
  font-size: $font-size-m;
  line-height: 21px;
  color: $color-gray-150;
  font-weight: $font-weight-bold;
  margin-bottom: $margin-2xs;
}

.avg_txt {
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-gray-150;
  font-weight: $font-weight-regular;
}
