@import "styles/_variables.scss";

.row {
  display: flex;
  flex-direction: row;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  width: 540px;
  max-width: 100vw;
  min-width: 200px;
  margin: 0;
}

.header {
  font-family: $font-family-default;
  font-style: normal;
  color: $color-header;
  font-weight: $font-weight-bold;
  font-size: $font-size-8xl;
  line-height: 41px;
  padding-bottom: $padding-s;
}

.txt {
  color: $color-input-label;
  font-size: $font-size-m;
  font-weight: $font-weight-regular;
  line-height: 18px;
  padding-bottom: 32px;
}

//inputs container

.red {
  color: $color-error;
}

.reminder_container {
  @extend .row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $font-size-xs;
  font-weight: $font-weight-regular;
  line-height: 16px;
  color: $color-cta;
  margin: $margin-m 0;
}

.link_forgot {
  text-decoration: none;
  color: $color-cta;
}

.error {
  height: 50px;
  border: 1px solid $color-grey-600;
  box-shadow: $shadow-input;
  border-radius: 8px;
  font-weight: $font-weight-medium;
  font-size: 16px;
  line-height: 18px;
  padding-left: $padding-s;
  color: $color-input-txt;
  border: 1px solid $color-error;
  background: $color-error-background;
}

.error_password {
  color: $color-error;
  font-size: $font-size-2xs;
  line-height: 13px;
  font-weight: $font-weight-regular;
  margin-top: $margin-xs;
  margin-left: $margin-2xs;
}

.contact_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: $margin-l 0;
  line-height: 16px;
  letter-spacing: 0.025em;
  color: $color-input-label;
  font-weight: $font-weight-regular;
}

.txt_account {
  color: $color-txt;
  margin-right: $margin-2xs;
}

.link_account {
  color: $color-cta;
  text-decoration: none;
}

.logo {
  margin: 40px 80px 20px 80px;
}

.password_wrapper {
  position: relative;
}

.show_password {
  position: absolute;
  right: 5px;
  bottom: -3px;
  cursor: pointer;
}

.txt_show {
  font-size: 14px;
  line-height: 16px;
  color: $color-input-label;
}
