@use 'sass:math';
@import "styles/_variables.scss";

.title1 {
  font-weight: $font-weight-medium;
  font-size: $font-size-2xl;
  line-height: 28px;
  color: $color-input-txt;
  margin-right: $margin-xl;
}

.txt {
  font-weight: $font-weight-medium;
  font-size: $font-size-xs;
  line-height: 16px;
}

.header_part {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $margin-s;
}

.accordion_header {
  padding: math.div($padding-m, 2) 0;
  background: $color-grey-200;
  border-radius: 6px 6px 0px 0px;
}

.accordion_panel {
  display: flex;
  flex-direction: column;
  background-color: $color-white;
}

.license_icon {
  display: flex;
  align-items: center;
  font-weight: $font-weight-medium;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-touched;
}

.icon_txt {
  width: 16px;
  height: 16px;
  margin-right: $margin-2xs;
}

.accordion_item {
  margin-bottom: $margin-xs;
}

@import "styles/_variables.scss";

.row {
  border: 1px solid $color-grey-600;
  border-radius: 6px;
  margin-bottom: $margin-xs;
  align-items: center;
}

.title {
  font-size: $font-size-s;
  line-height: 16px;
  margin: $margin-s;
  color: $color-input-txt;
  font-weight: $font-weight-regular;
  flex: 1;
}

.title2 {
  font-size: $font-size-s;
  line-height: 16px;
  margin: $margin-s;
  text-decoration: line-through;
  font-weight: $font-weight-regular;
  flex: 1;
}

.deprecated {
  text-decoration: line-through;
  font-weight: bold;
}

.date {
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-gray-40;
  flex: 1;
  text-align: end;
  margin-right: $margin-s;
}

.group {
  align-self: center;
}

.meatball {
  margin-right: $margin-s;
}

.data {
  font-weight: bold;
}
