@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.spinner_wrapper {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  opacity: 1;
  position: relative;
  transition: opacity linear 0.1s;
  &::before {
    animation: spinner 2s linear infinite;
    border: solid 10px #eee;
    border-bottom-color: #ee7203;
    border-radius: 50%;
    content: "";
    height: 80px;
    left: 50%;
    opacity: inherit;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transform-origin: center;
    width: 80px;
    will-change: transform;
  }
}

.spinner_small {
  opacity: 1;
  position: relative;
  transition: opacity linear 0.1s;
  &::before {
    animation: spinner 2s linear infinite;
    border: solid 4px #eee;
    border-bottom-color: #ee7203;
    border-radius: 50%;
    content: "";
    height: 25px;
    left: 50%;
    opacity: inherit;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transform-origin: center;
    width: 25px;
    will-change: transform;
  }
}
