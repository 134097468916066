@use 'sass:math';
@import "styles/_variables.scss";

.form {
  padding: $padding-s;
  background: $color-cta-light-bl;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: $margin-s;
}

.start_row {
  @extend .row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.red {
  color: $color-error;
}

.separator {
  border: 0.5px solid $color-grey-600;
}

.dropdown {
  justify-content: center;
  width: 200px;
  margin: 0 math.div($margin-m, 2);
}
.dropdown_config {
  justify-content: center;
  width: 200px;
  margin: 0 0 30px 0;
}

.add_btn {
  border: 1px solid $color-cta-orange;
  border-radius: 4px;
  display: flex;
  align-self: center;
  padding: 10px;
  background: $color-cta-orange;
  margin-left: math.div($margin-m, 2);
  cursor: pointer;
}

.add_icon {
  width: 14px;
  height: 14px;
}

.checkbox_required {
  display: flex;
  align-items: center;
}

.section_name {
  display: flex;
  align-items: center;
  background: $color-cta-light-bl;
  font-weight: $font-weight-medium;
  font-size: $font-size-s;
  line-height: 18px;
  color: $color-input-txt;
  padding: $padding-s 0 $padding-s $padding-2xs;
}

.button_section {
  display: flex;
  justify-content: flex-end;
}

.delete_btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.delete_icon {
  margin-left: 12px;
  cursor: pointer;
}

.trash {
  height: fit-content;
  display: flex;
  align-items: flex-start;
  margin-left: auto;
  cursor: pointer;
}

.icon_box {
  border: 1px solid $color-grey-600;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 12px;
  margin-left: math.div($margin-m, 2);
  background: $color-white;
}

.icon {
  height: 14px;
  width: 11px;
}

.add_section {
  font-size: $font-size-2xs;
  line-height: 14px;
  color: $color-cta-orange;
  margin-bottom: $margin-xl;
}

.border {
  border-right: 1px solid $color-cta-orange;
  margin-left: $margin-2xs;
  padding-right: $padding-s;
}

.right {
  margin-left: math.div($margin-xl, 2);
}

.date_section {
  display: flex;
  justify-content: row;
  align-items: center;
}

.error {
  color: $color-error;
  font-size: $font-size-2xs;
  line-height: 13px;
  font-weight: $font-weight-regular;
  margin-top: $margin-xs;
  margin-left: $margin-2xs;
}

.btn_separator {
  margin-left: math.div($margin-xl, 2);
}

.property_edited_warning {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: $color-error;
  margin-bottom: $margin-l;
}

.rules_editor_button {
  font-size: $font-size-2xs;
  line-height: 14px;
  color: $color-cta-orange;
  margin-bottom: $margin-s;
  cursor: pointer;
}

@media (max-width: 767px) {
  .modal {
    min-width: 700px;
  }
  .row {
    flex-direction: column;
  }
  .add_btn {
    margin-top: math.div($margin-m, 2);
  }
  .checkbox_required {
    margin-top: math.div($margin-m, 2);
  }
}

@media (min-width: 340px) {
  .modal {
    min-width: 300px;
    margin-left: $margin-3xl;
  }
  .width {
    min-width: 300px;
  }
}
