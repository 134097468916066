@import "styles/_variables.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  width: 540px;
  max-width: 100vw;
  min-width: 200px;
  margin: 0;
}

.icon_circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1.5px solid $color-input-label;
  margin-bottom: $margin-2xl;
  box-shadow: 9px 7px 5px 1px rgba(241, 241, 241, 1);
}

.icon_circleRed {
  @extend .icon_circle;
  border: 1.5px solid $color-error;
}

.icon {
  height: 40px;
  width: 26px;
}

.header {
  font-family: $font-family-default;
  font-style: normal;
  color: $color-header;
  font-weight: $font-weight-bold;
  font-size: $font-size-8xl;
  line-height: 41px;
  margin-bottom: $margin-s;
}

.txt_container {
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 395px;
  color: $color-input-label;
  font-size: $font-size-xs;
  font-weight: $font-weight-regular;
  line-height: 24px;
  height: 48px;
  margin-bottom: $margin-l;
}

//inputs container

.red {
  color: $color-error;
}

.link_return {
  position: fixed;
  bottom: 52px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: $color-cta;
  cursor: pointer;
}

//resend code

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: $font-size-xs;
  font-weight: $font-weight-regular;
  line-height: 16px;
  color: $color-cta;
  margin: $margin-m 0;
}

.resend_code_button {
  margin-top: -$margin-xl;
  margin-left: $margin-l;
  text-decoration: none;
  color: $color-cta;
  cursor: pointer;
}
