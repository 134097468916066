@use 'sass:math';
@import "styles/_variables.scss";

.menu_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.menu_trigger {
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu {
  background: $color-white;
  border: 1px solid $color-cta-orange;
  z-index: 1;
  border-radius: 6px;
  position: absolute;
  top: 25px;
  right: -19px;
  width: 180px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(0);
  overflow: hidden;
}

.menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.menu :hover {
  background: #eaeaea;
}

.item_container {
  display: flex;
  align-items: center;
  padding: math.div($margin-m, 2);
  cursor: pointer;
}

.icon {
  width: 14px;
  height: 14px;
}

.icon_menu {
  width: 9px;
  height: 12px;
}

.label_menu {
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
  margin-left: $margin-xs;
}
