@import "styles/_variables.scss";

.width {
  width: 392px;
}

.red {
  color: $color-error;
}

.modal {
  flex-basis: 876px;
}

.start_row {
  display: flex;
  flex-wrap: wrap;
}

.add_txt {
  font-size: $font-size-s;
  line-height: 18px;
  color: $color-cta-orange;
  cursor: pointer;
}

.margin_bottom {
  margin-bottom: $margin-s;
}

.separator {
  height: 40px;
}

@media (min-width: 767px) {
  .modal {
    min-width: 700px;
  }
}

@media (min-width: 340px) {
  .modal {
    min-width: 300px;
    margin-left: 50px;
  }
  .width {
    min-width: 300px;
  }
}
