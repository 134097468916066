@import "styles/_variables.scss";

.container {
  width: 100%;
  max-width: 100%;
}

.assetsList {
  width: 100%;
  max-width: 100%;
  height: fit-content;
  max-height: 530px;
  margin-top: -30px;
  padding: 10px 10px 120px;
  border: 1px solid $color-grey-600;
  border-radius: 6px;
  overflow-y: hidden;
  overflow-x: auto;
}

.assetItems {
  width: fit-content;
  height: 400px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.assetItem {
  width: fit-content;
  height: 50px;
  display: flex;
  border: 1px solid $color-grey-600;
  border-radius: 6px;
  margin-bottom: 5px;
}

.assetItem_conflicted {
  @extend .assetItem;
  background: $color-error-background;
}

.assetsListHeader {
  width: fit-content;
  height: fit-content;
  display: flex;
  border: 1px solid $color-grey-600;
  background: $color-cta-light-orange;
  border-radius: 6px;
  margin-bottom: 10px;
  font-weight: 500;

  .headerCell {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 250px;
    padding: 5px;
    border-left: 1px solid $color-grey-600;

    &:first-of-type {
      border-left: none;
    }

    .headerCellLabel {
      margin-bottom: 5px;
    }

    .selectFilter {
      position: relative;
      width: calc(100% - 25px);
      height: fit-content;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $color-grey-600;
      border-radius: 6px;
      margin-right: 10px;
      background: $color-white;
      cursor: pointer;

      .selectFilterTextSelected {
        font-weight: 400;
        font-size: $font-size-xs;
        line-height: 14px;
        padding: 5px;
      }

      .selectFilterText {
        @extend .selectFilterTextSelected;
        color: $color-grey-800;
      }

      .selectFilterIcon{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
      }
    }

    .filterOptionsList {
      position: absolute;
      z-index: 100;
      top: calc(100% - 3px);
      width: calc(100% - 10px);
      max-height: 265px;
      background: $color-white;
      border: 1px solid $color-grey-600;
      border-radius: 6px;
      overflow-x: hidden;
      overflow-y: auto;

      .filterOptionSingle {
        width: 100%;
        font-weight: 400;
        font-size: $font-size-xs;
        line-height: 14px;
        padding: 5px;
        cursor: pointer;

        &:hover {
          background: $color-cta-orange;
          color: $color-white;
        }
      }

      .filterOptionSingleSelected {
        @extend .filterOptionSingle;
        font-weight: 500;
        color: $color-cta-orange;
      }

      .filterOptionMulti {
        width: 100%;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: $font-size-xs;
        line-height: 14px;
        padding: 5px;
        cursor: pointer;

        &:hover {
          background: $color-cta-orange;
          color: $color-white;
        }
      }

      .filterInputsWrapper {
        width: calc(100% - 14px);
        display: flex;

        .filterInputWrapper {
          position: relative;
          width: 50%;

          :global(.react-datepicker__input-container) input {
            text-align: start;
            background: $color-white;
            border: 1px solid $color-grey-600;
            border-radius: 6px;
            padding: $padding-xs;
            margin-right: 6px;
            font-size: $font-size-xs;
            line-height: 16px;
            color: $color-input-txt;
            width: 100%;
            height: 24px;

            &:focus {
              outline: 1px solid $color-cta-orange;
            }
          }

          :global(.react-datepicker__month-container) {
            border: 1px solid $color-cta-orange;
          }

          :global(.react-datepicker__input-container) input[readOnly] {
            background: $color-grey-500;
            opacity:0.5;
            border: 1px sold $color-grey-600;
          }

          :global(.react-datepicker__day--selected) {
            background: $color-cta-orange;
            border-radius: 50%;
          }

          :global(.react-datepicker__header) {
            background: $color-white;
            border: none;
          }

          :global(.react-datepicker__triangle) {
            display: none;
          }

          .icon {
            position: absolute;
            right: 10px;
            top: 30px;
            width: 16px;
            height: 16px;
          }
        }
      }

      .filterInputLabel {
        width: calc(100% - 14px);
        margin: 5px 7px 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
      }

      .picker_wrapper {
        display: inline;
        width: fit-content;
        cursor: pointer;
      }

      .filterInput {
        width: calc(100% - 14px);
        height: 24px;
        margin: 7px;
        padding: 0 3px;
        border: 1px solid $color-grey-600;
        border-radius: 3px;

        &:focus {
          outline: 1px solid $color-cta-orange;
        }
      }

      .filterInputDate {
        width: calc(100% - 14px);
        height: 24px;
        margin: 7px;
      }

      .applyFilterButton {
        width: fit-content;
        height: 24px;
        display: flex;
        align-items: center;
        margin: 0 7px 5px;
        padding: 2px 10px;
        border: 1px solid $color-cta-orange;
        border-radius: 6px;
        background: $color-cta-orange;
        color: $color-white;
        font-weight: 400;
        font-size: $font-size-xs;
        line-height: 14px;
        cursor: pointer;
      }
    }

    .selectOptions {
      width: 100%;
      height: 25px;
      display: flex;
      align-items: center;

      .selectOption {
        height: 25px;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: $font-size-xs;
        line-height: 14px;
        margin-right: 20px;
        cursor: pointer;

        .selectOptionLabel {
          cursor: pointer;
        }
      }
    }

    .filterOptionsListDate {
      @extend .filterOptionsList;
      width: calc(100% + 12px);
      height: 338px;
      max-height: 338px;
    }
  }

  .headerCellName {
    @extend .headerCell;
    width: 300px;
  }

  .sortButtons {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sortButton {
      width: 15px;
      height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $color-grey-600;
      border-radius: 3px;
      font-size: $font-size-2xs;
      color: $color-grey-50;
      cursor: pointer;
    }
  }

  .clearFilterIcon {
    position: absolute;
    bottom: 2px;
    right: -3px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}

.invertButton {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 1px solid $color-grey-600;
  border-radius: 3px;
  font-size: $font-size-xs;
  background: $color-white;
  cursor: pointer;

  .invertIcon {
    height: 23px;
    width: auto;
    margin-right: 2px;
  }
}

.invertButtonLabel {
  margin-left: 10px;
}

.assetItemNameCell {
  width: 300px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 5px;

  .assetItemLabel {
    width: calc(100% - 30px);

    .assetItemName {
      width: 100%;
      margin-bottom: 2px;
    }

    .assetItemPath {
      width: 100%;
      font-size: $font-size-2xs;
      color: $color-grey-50;
    }
  }
}

.assetCheckbox {
  min-width: 20px;
  width: 20px;
  height: 20px;
  border: 1px solid $color-grey-600;
  border-radius: 2px;
  margin-right: 10px;
  background: $color-white;
  cursor: pointer;
}

.assetCheckboxChecked {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $color-grey-600;
  border-radius: 4px;
  margin-right: 10px;
  background: $color-cta-orange;
  font-weight: 500;
  font-size: $font-size-s;
  color: $color-white;
  cursor: pointer;
}

.assetItemCell {
  width: 250px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 5px;
  border-left: 1px solid $color-grey-600;

  .propertyLabel {
    height: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    overflow-y: auto;
  }
}
