@import "../../styles/_variables.scss";

.task_history {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .task_history_header {
    width: fit-content;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 7px 5px 10px;
    background: $color-cta-light-bl;
    border-radius: 5px;
    cursor: pointer;

    .task_history_header_title {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding-right: 20px;
    }

    .task_history_header_icon {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      .task_history_icon_up {
        transform: rotate(180deg);
      }
    }
  }

  .task_history_events_list {
    width: 100%;
    margin-top: 3px;
    padding: 8px 10px;
    background: $color-cta-light-bl;
    border-radius: 5px;

    .task_history_event {
      width: 100%;
      padding: 2px 0;
      font-size: 14px;
    }
  }
}
