@import "styles/_variables.scss";

.viewerContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background: $color-white;
  padding: 100px;

  .viewerScreen {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    canvas {
      border: 1px solid black;
    }
  }

  .menuContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 50px;
    right: 50px;
    width: 120px;

    .path {
      width: fit-content;
      white-space: nowrap;
      margin-right: $margin-s;
      font-weight: 500;
      font-size: 18px;
      color: $color-cta-orange;
    }

    .editIconBorder{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      min-width: 32px;
      height: 32px;
      border: 1px solid $color-touched;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
      margin-right: 10px;
    }

    .disabledEditTooltip {
      display: none;
      position: absolute;
      width: 180px;
      top: -10px;
      right: 15px;
      padding: $padding-2xs;
      background: rgba($color-cta-orange, 0.6);
      border-radius: 4px;
      text-align: center;
      z-index: 2;
    }

    .editIconBorderDisabled {
      @extend .editIconBorder;
      position: relative;
      border-color: $color-grey-500;
      background: $color-grey-20;
      cursor: default;

      &:hover .disabledEditTooltip {
        display: flex;
      }
    }

    .editIcon {
      width: 20px;
      height: 20px;
    }

    .closeIconBorder{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      min-width: 32px;
      height: 32px;
    }
  }

  .toolbarContainer{
    position: absolute;
    height: 40px;
    bottom: 20px;
    width: calc(100% - 200px);
    display: flex;
    align-items: center;
    justify-content: space-around;

    .zoomContainer {
      display: flex;
      align-items: center;

      svg {
        margin: 0 15px;
      }

      .zoomValue {
        margin-left: 15px;
        font-weight: 500;
        font-size: 18px;
      }
    }

    .button{
      width: 100px;
      min-width: 100px;
    }
  }

  .unsetPoisContainer {
    position: absolute;
    width: 200px;
    top: 100px;
    left: 50px;
    border: 1px solid $color-border-input;
    background: $color-cta-light-orange;

    .unsetPoisLabel {
      padding: 5px;
      background: $color-cta-light-bl;
      border-bottom: 1px solid $color-border-input;
      font-size: 16px;
      font-weight: 500;
    }

    .unsetPoiItem {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 5px;
      border-bottom: 1px solid $color-border-input;
      font-size: 16px;
      cursor: pointer;
    }

    .unsetPoiItem:last-of-type {
      border-bottom: none;
    }
  }

  .filtersContainer {
    position: absolute;
    width: 200px;
    top: 100px;
    right: 50px;

    .filterLabel {
      padding: 5px;
      margin-bottom: $margin-2xs;
      background: $color-cta-light-bl;
      border: 1px solid $color-border-input;
      font-size: 16px;
      font-weight: 500;
    }

    .filterItem {
      width: 100%;
      margin-bottom: $margin-2xs;
    }
  }
}
