@use 'sass:math';
@import 'styles/_variables.scss';

.dropdown {
    margin-bottom: 40px;
}

.red {
    color: $color-error;
}

.button_section {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: $margin-l $margin-xl $margin-l 0;
}

.btn_separator {
    margin-left: math.div($margin-xl, 2);
}

.addFileLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 100%;
    height: 40px;
    box-shadow: 0 1px 5px #0000001A;
    border: 1px solid $asset-2;
    border-radius: 5px;
    font-size: $font-size-s;
    font-weight: 400;
    color: $asset-2;
    cursor: pointer;
}

.addFileBtn {
    padding: $padding-xs;
    border-radius: 8px;
    font-style: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: $color-white;
    border: 1px solid $color-cta-orange;
    background: $color-cta-orange;
    position: relative;
    cursor: pointer;
}

.addFileInput {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.noFileErrorMessage {
    width: 100%;
    font-size: $font-size-xs;
    font-weight: 400;
    color: $color-error;
    padding: $padding-2xs 0;
}
