@import "styles/_variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  height: 50px;
  background: $color-white;
  border-top: 1px solid $color-border-input;
}

.section_left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider {
  margin-right: 15px;
  border-left: 1px solid $color-grey-600;
  height: 50px;
}

.txt {
  font-size: $font-size-s;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: $color-header;
  margin-left: $margin-s;
}

.joined_icons,
.background_icon {
  position: relative;
  left: 0;
  top: 0;
}

.top_icon {
  position: absolute;
  top: 8px;
  left: 7px;
  height: 10px;
  width: 13px;
}

.delete_icon {
  cursor: pointer;
}

.badge {
  display: flex;
  font-size: $font-size-2xs;
  margin: $margin-s;
  background-color: $color-grey-20;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}
