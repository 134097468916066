@import "styles/_variables.scss";

.column {
  display: flex;
  flex-direction: column;
}

.filters_section {
  margin-top: $margin-m;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row {
  display: flex;
  flex-direction: row;
  padding-top: $padding-xs;
}

.buttonLeft {
  margin-right: $margin-s;
}

.checkbox_container {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  margin-bottom: $margin-s;
}

.checkbox {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  background-color: $color-white;
  border: 1px solid $color-border-input;
  padding: 9px;
  border-radius: 8px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.checkbox:checked {
  background-color: $color-white;
  border: 2px solid $color-cta-orange;
  color: #99a1a7;
}

.checkbox:checked:after {
  content: '\2714';
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 3px;
  color: $color-cta-orange;
}

.checkbox:disabled {
  background-color: $color-grey-20;
  cursor: default;
}

.checkbox_label {
  width: fit-content;
  white-space: nowrap;
  margin-left: $margin-2xs;
  font-size: $font-size-xs;
  font-weight: $font-weight-regular;
}
