@import "styles/_variables.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  display: flex;
  justify-content: row;
}

.qrwrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .assetName {
    margin-top: 10px;
  }
}

.buttonsContainer {
  display: flex;
}

.buttonWrapper {
  margin-bottom: 15px;
  width: 200px;
}

.column_txt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.txt {
  font-weight: $font-weight-medium;
  font-size: $font-size-s;
  line-height: 18px;
  color: $color-input-txt;
  margin-bottom: $margin-xs;
}

.txt1 {
  font-size: $font-size-xs;
  line-height: 16px;
  font-weight: $font-weight-regular;
  color: $color-input-txt;
}
