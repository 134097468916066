@import "styles/_variables.scss";

.users_access {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.btn_containerRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.btn_containerRight_open {
  @extend .btn_containerRight;
  background: $color-grey-20;
}

.title {
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
  width: 50px;
  min-width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  margin-right: $margin-xs;
}

.add_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-left: auto;
  cursor: pointer;

  .add_icon {
    width: 14px;
    height: 14px;
  }
}

.search_input {
  width: calc(100% - 2 * $margin-xs);
  margin: $margin-xs;
}

.users_list {
  list-style-type: none;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  background: $color-white;
}

.list_item {
  width: 100%;
  display: flex;
  align-items: center;
}

.remove_user_access_button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.checkbox {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  background-color: $color-white;
  border: 1px solid $color-border-input;
  padding: 9px;
  border-radius: 8px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.checkbox:checked {
  background-color: $color-white;
  border: 2px solid $color-cta-orange;
  color: #99a1a7;
}

.checkbox:checked:after {
  content: '\2714';
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 3px;
  color: $color-cta-orange;
}

.checkbox:disabled {
  background-color: $color-grey-20;
  cursor: default;
}

.checkbox_label {
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-2xs;
  line-height: 16px;
  padding: $padding-xs;
  color: $color-input-txt;
  cursor: pointer;
}

.button_section {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: $margin-l $margin-xl $margin-l 0;
}

.btn_separator {
  margin-left: $margin-xs;
}

.button_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: $padding-xs;
}
