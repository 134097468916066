@import "styles/_variables.scss";

.container {
  width: 100%;
  max-height: 400px;
  background: $color-white;
  padding: 10px $padding-s;
  overflow-y: auto;
}

.alertList {
  list-style-type: none;
  padding-left: $padding-s;
}
