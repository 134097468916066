@import "styles/_variables.scss";

.modal {
  width: calc(100% - 80px);
  min-width: calc(100% - 80px);
  max-height: calc(100% - 80px);
}

.isLoading {
  margin-top: 50px;
}

.explorer {
  height: 100%;
  width: 100%;
  padding-top: $padding-s;

  .actionsBar {
    height: 38px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: $margin-s;
  }

  .filters {
    height: 100%;
    width: 100%;
    display: flex;

    .datePickerContainer {
      position: relative;
      height: 38px;

      .datePickerIcon {
        position: absolute;
        right: 20px;
        top: 10px;
      }

      .datePickerWrapper {
        width: fit-content;
        height: 38px;

        input {
          height: 38px;
          border-color: $color-grey-60;
          border-radius: 6px;
        }
      }
    }
  }

  .parentAssetUpload {
    margin-bottom: $margin-s;
  }

  .uploadInputContainer {
    position: relative;
    width: 100%;
    height: 50px;
    margin-top: $margin-xs;
    border: 1px solid $color-grey-600;
    border-radius: 8px;

    .uploadText {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: linear-gradient(45deg, #ffffff 25%, $color-grey-600 25%, $color-grey-600 50%, #ffffff 50%, #ffffff 75%, $color-grey-600 75%, $color-grey-600 100%);
      background-size: 28.28px 28.28px;
      color: $color-grey-100;
      border-radius: 8px;
    }

    .uploadTextActive {
      @extend .uploadText;

      background-image: linear-gradient(45deg, #ffffff 25%, $color-cta-orange 25%, $color-cta-orange 50%, #ffffff 50%, #ffffff 75%, $color-cta-orange 75%, $color-cta-orange 100%);
    }

    .dragFile {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .selectAllWrapper {
    height: 20px;
    display: flex;
    align-items: center;
    margin-bottom: $margin-xs;

    .attachmentsCheckbox {
      width: 30px;
      min-width: 30px;
    }

    .checkboxLabel {
      font-size: $font-size-xs;
      line-height: 16px;
    }
  }

  .checkbox {
    min-width: 20px;
    width: 20px;
    height: 20px;
    border: 1px solid $color-grey-600;
    border-radius: 2px;
    margin-right: 10px;
    background: $color-white;
    cursor: pointer;
  }

  .checkboxChecked {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-grey-600;
    border-radius: 4px;
    margin-right: 10px;
    background: $color-cta-orange;
    font-weight: 500;
    font-size: $font-size-s;
    color: $color-white;
    cursor: pointer;
  }

  .list {
    width: 100%;
    max-height: calc(100% - 54px);
    display: flex;
    flex-direction: column;
    gap: $margin-xs;

    .listItem {
      width: 100%;
      padding: $padding-xs;
      border: 1px solid $color-grey-600;
      border-radius: 8px;

      .row {
        width: 100%;
        display: flex;

        .name {
          width: 20%;
        }

        .path {
          width: 80%;
          font-size: $font-size-xs;
          line-height: 16px;
          padding-left: $padding-s;
          color: $color-input-txt;
        }

        .uploadButton {
          width: 20px;
          height: 20px;
          cursor: pointer;

          .iconUpload {
            width: auto;
            height: 20px;
          }

          .iconClose {
            width: auto;
            height: 22px;
          }
        }

        .attachments {
          margin-top: $margin-xs;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: $margin-2xs;
          background: $color-cta-light-orange;
          border: 1px solid $color-grey-600;
          border-radius: 8px;
          padding: $padding-xs;

          .attachmentsHeader {
            width: 100%;
            display: flex;
            align-items: center;
            font-size: $font-size-xs;
            line-height: 16px;
            padding: 0 $padding-xs $padding-2xs;
            color: $color-input-txt;

            .attachmentsHeaderCheckbox {
              width: 30px;
              min-width: 30px;
            }

            .attachmentsHeaderItem {
              width: calc((90% - 30px) / 5);
            }
          }

          .attachmentItem {
            width: 100%;
            display: flex;
            background: $color-white;
            border: 1px solid $color-grey-600;
            border-radius: 8px;
            padding: $padding-xs;

            .attachmentItemCheckbox {
              width: 30px;
            }

            .attachmentItemField {
              width: calc((90% - 30px) / 5);

              path {
                color: $color-cta-orange
              }
            }

            .attachmentItemActionsField {
              width: 10%;
              display: flex;
              justify-content: flex-end;
              align-items: center;

              .actionButton {
                width: 20px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: $margin-s;
                cursor: pointer;
              }

              .icon {
                width: auto;
                height: 16px;

                path {
                  fill: #707070;
                }
              }

              .iconOpen {
                @extend .icon;
                width: auto;
                height: 18px;
              }
            }
          }
        }
      }
    }
  }
}
