@import "styles/_variables.scss";

.column {
  display: flex;
  flex-direction: column;
}

.dropdown {
  padding: $padding-l 0;
}

.assets_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: $color-grey-500;
  font-size: $font-size-2xs;
  line-height: 14px;
  text-transform: uppercase;
  color: $color-gray-40;
  margin-bottom: $margin-xs;
}
