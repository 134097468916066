@use 'sass:math';
@import "styles/_variables.scss";

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.txt {
  font-size: $font-size-m;
  line-height: 21px;
  color: $color-input-label;
  font-weight: $font-weight-regular;
  margin: math.div($margin-xl, 2) 0;
}

.txt1 {
  font-size: $font-size-xs;
  line-height: 16px;
}

.txt2 {
  @extend .txt1;
  margin-top: $margin-s;
}

.title {
  margin-right: $margin-xs;
}

.right_content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon_box {
  border: 1px solid $color-grey-600;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 12px;
  margin-left: math.div($margin-m, 2);
  cursor: pointer;
}

.icon {
  height: 14px;
  width: 11px;
}
