@use 'sass:math';
@import "styles/_variables.scss";

.pieChartsWrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0.33%;
  margin-top: $margin-l;
}

.container {
  width: 33%;
  min-width: 500px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.contained_cell {
  width: 100%;
  max-width: 100%;
  background: $color-white;
  border: 1px solid $color-grey-600;
  box-sizing: border-box;
  border-radius: 6px;
  flex: 1;
  margin: 5px;
  overflow: hidden;
}

.containedHeader {
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: space-between;
  font-size: $font-size-m;
  font-weight: $font-weight-semi-bold;
  background-color: $color-grey-200;
  padding: 15px;
  border-bottom: 1px solid $color-grey-600;
}

.pieChartContainer {
  height: calc(100% - 60px);
  display: flex;
  justify-content: space-between;
}

.tasksInfo {
  min-height: 200px;
  padding: 15px;
}

.pieChart {
  min-height: 200px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chartBox {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.base {
  position: absolute;
  width: 100px;
  top: calc(50% - 12px);
  right: 55px;
  line-height: 24px;
  font-size: $font-size-xl;
  font-weight: $font-weight-medium;
  text-align: center;
}
