@use 'sass:math';
@import "styles/_variables.scss";

.width {
  width: 392px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.fullRow {
  display: flex;
  justify-content: space-between;
}

.fullRowLeft {
  display: flex;
  gap: $margin-l;
}

.red {
  color: $color-error;
}

.modal {
  flex-basis: 876px;
}

.add_txt {
  font-size: $font-size-s;
  line-height: 18px;
  color: $color-cta-orange;
  cursor: pointer;
}

.alert_dropdown {
  width: 190px;
}

.width_dropdown {
  width: 190px;
  margin-bottom: $margin-l;
}

.width_dropdown2 {
  @extend .width_dropdown;
  margin-left: 20px;
}

.number_section {
  padding-left: math.div($padding-m, 2);
}

.asset_number {
  display: flex;
  align-items: center;
  border: 1px solid $color-grey-600;
  border-radius: 8px;
  font-weight: $font-weight-regular;
  font-size: $font-size-xs;
  line-height: 16px;
  padding-left: $padding-s;
  color: $color-input-txt;
  outline: none;
  width: 89px;
  height: 38px;
}

.assetOptionLabel {

  .assetOptionName {

  }

  .assetOptionPath {
    font-size: $font-size-2xs;
    line-height: 16px;
    color: $color-grey-50;
  }
}

@media (min-width: 767px) {
  .modal {
    min-width: 700px;
  }
}

@media (min-width: 340px) {
  .modal {
    min-width: 300px;
    margin-left: 50px;
  }
  .width {
    min-width: 300px;
  }
}
