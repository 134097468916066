@use 'sass:math';
@import "styles/_variables.scss";

.accordion_header {
  height: 50px;
  flex: auto;
  background: $color-white;
}

.accordion_panel {
  display: flex;
  flex-direction: column;
  padding-left: ($padding-l * 2);
  background-color: $color-white;
  padding-right: math.div($padding-xl, 2);
}

.assets_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: $color-grey-500;
  font-size: $font-size-2xs;
  line-height: 14px;
  text-transform: uppercase;
  color: $color-gray-40;
  margin-bottom: $margin-xs;
}

.txt1 {
  flex-basis: 160px;
}

.txt2 {
  flex-basis: 217px;
}

.txt3 {
  flex: 1;
}

.txt4 {
  flex: 0;
  margin-right: 230px;
}

.border {
  border: 1px solid $color-grey-600;
  border-radius: 6px;
  margin-bottom: $margin-xs;
  align-items: center;
  padding: $padding-m;
}

.task_content {
  @extend .border;
  background: $color-grey-200;
}

.task_name {
  font-size: $font-size-s;
  line-height: 16px;
  color: $color-input-txt;
  font-weight: $font-weight-regular;
  flex-grow: 0;
  width: 100px;
  word-wrap: break-word;
}

.dropdown {
  justify-content: center;
  min-width: 200px;
  margin-right: $margin-s;
  padding-bottom: $margin-l;
}

.form_part {
  flex-grow: 1;
}

.form {
  background: inherit;
}

.column {
  margin-left: auto;
}

.usage {
  padding-bottom: $margin-l;
}

.button_section {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: $margin-s;
}

.btn_separator {
  margin-left: $margin-s;
}

.btn_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: $margin-l;
}

.modal {
  position: relative;
  flex-basis: 876px;
}

@media (max-width: 767px) {
  .modal {
    min-width: 700px;
  }
}

@media (min-width: 340px) {
  .modal {
    min-width: 300px;
    margin-left: $margin-3xl;
  }
}
