@use 'sass:math';
@import 'styles/_variables.scss';

.modal {
    width: 392px;
}
.button_section {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: $margin-l $margin-xl $margin-l 0;
  }
  
  .btn_separator {
    margin-right: math.div($margin-m, 2);
  }
  
  .delete_txt {
    margin-top: $margin-2xl;
    font-size: $font-size-xs;
    line-height: 22px;
    color: $color-input-txt;
  }
  
  .spacing_txt {
    margin: $margin-s 0;
    font-size: $font-size-xs;
    line-height: 16px;
    color: $color-gray-40;
    text-align: left;
  }
  
  .red {
    color: $color-error;
  }
  