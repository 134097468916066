@import "styles/_variables.scss";

.input {
  width: 100%;
  height: 72px;
  border: 1px solid $color-grey-600;
  border-radius: 6px;
  overflow-wrap: break-word;
  background: $color-white;
  padding: $padding-xs 0 0 $padding-xs;
  resize: none;
  outline: none;
  color: $color-input-txt;
}

.input2 {
  width: 100%;
  height: 72px;
  border: 1px solid $color-grey-600;
  border-radius: 6px;
  overflow-wrap: break-word;
  background: $color-grey-600;
  opacity: 0.45;
  padding: $padding-xs 0 0 $padding-xs;
  resize: none;
  outline: none;
  color: $color-input-txt;
}

.input:focus {
  border: 1px solid $color-cta-orange;
  background: $color-white;
}

.error {
  @extend .input;
  border: 1px solid $color-error;
  background: $color-white;
}

.valid {
  @extend .input;
  border: 1px solid $color-grey-600;
  background: $color-white;
}

