@import "styles/_variables.scss";

.notification-container {
    font-size: $font-size-xs;
    position: fixed;
    z-index: 10000;
    min-width: 300px;
}

.top-right {
    top: 12px;
    right: 12px;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
}

.bottom-right {
    bottom: 12px;
    right: 12px;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
}

.notification:hover {
    cursor: pointer
}

.notification-body {
    display: flex;
    font-weight: $font-weight-medium;
    padding: $padding-xs $padding-xs $padding-m $padding-xs;
    font-size: $font-size-s;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.notification-title {
    font-weight: $font-weight-medium;
    font-size: $font-size-s;
    text-align: center;
}

.notification-message {
    white-space: wrap;
    font-size: $font-size-s;
    text-align: center;
}

.toast {
    min-height: 40px;
    max-width: 300px;
    border-radius: 4px;
    color: $color-cta-bg;
}

.close {
    display: flex;
    justify-content: flex-end;
    font-weight: $font-weight-medium;
    color: $color-cta-bg;
    cursor: pointer;
    background: 0 0;
    border: 0;
    margin-left: auto;
}

@keyframes toast-in-right {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}
