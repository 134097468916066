@use 'sass:math';
@import "styles/_variables.scss";

.task_list_container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin: 0;
  }
}
