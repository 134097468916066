@use 'sass:math';
@import "styles/_variables.scss";

.modal {
  flex-basis: 876px;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.row_section {
  @extend .row;
  align-items: center;
}

.start_row {
  @extend .row;
  justify-content: flex-start;
  margin-bottom: $margin-xs;
}

.red {
  color: $color-error;
}

.radio_field {
  margin-bottom: 0;
}

.separator {
  border: 0.5px solid $color-grey-600;
}

.dropdown {
  justify-content: center;
  width: 200px;
  margin: 0 math.div($margin-m, 2);
}

.add_btn {
  border: 1px solid $color-cta-orange;
  border-radius: 4px;
  display: flex;
  align-self: center;
  padding: 10px;
  background: $color-cta-orange;
  margin-left: math.div($margin-m, 2);
  margin-top: 3px;
  color: $color-white;
  cursor: pointer;
}

.add_icon {
  width: 14px;
  height: 14px;
}

.checkbox_required {
  display: flex;
  align-items: center;
  padding-top: 7px;
}

.delete_btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.add_section {
  display: flex;
  flex-direction: row;
  font-size: $font-size-2xs;
  line-height: 14px;
  color: $color-cta-orange;
  margin-bottom: $margin-s;
}

.cursor {
  cursor: pointer;
}

.border {
  border-right: 1px solid $color-cta-orange;
  margin: 0 $margin-m;
}

.right {
  margin-left: math.div($margin-xl, 2);
}

.date_section {
  display: flex;
  justify-content: row;
  align-items: center;
}

.rule_dropdown {
  width: 291px;
  margin-bottom: $margin-s;
}

.title {
  font-weight: $font-weight-medium;
  font-size: $font-size-s;
  line-height: 18px;
  color: $color-input-txt;
  margin-bottom: $margin-s;
}

.txt {
  font-weight: $font-weight-regular;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
  margin: math.div($margin-m, 2) math.div($margin-xl, 2) 0
    math.div($margin-xl, 2);
}

.textInfo {
  font-size: 12px;
  color: $color-gray-150;
}

.txt1 {
  font-weight: $font-weight-regular;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-cta-orange;
  margin: math.div($margin-m, 2) 0 0 0;
  cursor: pointer;
}

.delete_rules {
  margin: math.div($margin-m, 2) 0 0 0;
  margin-left: 16px;
}

.delete_icon {
  cursor: pointer;
}

.accordion_header {
  height: 40px;
  flex: auto;
}

@media (max-width: 767px) {
  .modal {
    min-width: 700px;
  }
  .row {
    flex-direction: column;
  }
  .add_btn {
    margin-top: math.div($margin-m, 2);
  }
  .checkbox_required {
    margin-top: math.div($margin-m, 2);
  }
}

@media (min-width: 340px) {
  .modal {
    min-width: 300px;
    margin-left: $margin-3xl;
  }
  .width {
    min-width: 300px;
  }
}
