@use 'sass:math';
@import "styles/_variables.scss";

.column {
  display: flex;
  flex-direction: column;
}

.filters_section {
  margin-top: $margin-m;
  height: 78px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row {
  display: flex;
  flex-direction: row;
  padding-top: $padding-xs;
}

.container_item {
  margin-bottom: $margin-xs;
}

.org_email {
  margin-left: 12px;
}

.accordion_header {
  background: $color-white;
}

.accordion_panel {
  display: flex;
  flex-direction: column;
  padding-left: ($padding-l * 2);
  background-color: $color-white;
  padding-right: math.div($padding-xl, 2);
}

.filters_section {
  margin-top: $margin-m;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttonLeft {
  margin-right: $margin-s;
}

.table1 {
  flex-grow: 1;
  border-right: 1px solid $color-grey-600;
}
.main_table {
  display: flex;
  flex-direction: row;
  border: 1px solid $color-grey-600;
  width: 100%;
  border-radius: 6px;
  margin-bottom: math.div($margin-xl, 2);
}

.title {
  flex: 1;
  display: flex;
  justify-content: space-between;
  font-size: $font-size-s;
  line-height: 16px;
  padding: $padding-s;
  color: $color-input-txt;
  font-weight: $font-weight-regular;
  border-bottom: 1px solid $color-grey-600;
}
