@import "styles/_variables.scss";

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-bottom: $margin-m;
}

.divider {
  border-left: 1px solid $color-grey-600;
  height: 50px;
}

.subtitle {
  flex: 2;
  font-size: $font-size-s;
  margin-left: $margin-s;
  color: $color-grey-50;
}
