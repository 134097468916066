@use 'sass:math';
@import "styles/_variables.scss";

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.start_row {
  @extend .row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.field {
  margin-right: $margin-s;
}

.red {
  color: $color-error;
}

.searchButton {
  padding: 15px 0;
}

.separator {
  border: 0.5px solid $color-grey-600;
}

.dropdown_config {
  justify-content: center;
  width: 392px;
  margin: 0 $margin-s 30px 0;
}

.error {
  color: $color-error;
  font-size: $font-size-2xs;
  line-height: 13px;
  font-weight: $font-weight-regular;
  margin-top: $margin-xs;
  margin-left: $margin-2xs;
}

@media (max-width: 767px) {
  .modal {
    min-width: 700px;
  }
  .row {
    flex-direction: column;
  }
}

@media (min-width: 340px) {
  .modal {
    min-width: 300px;
    margin-left: $margin-3xl;
  }
  .width {
    min-width: 300px;
  }
}
