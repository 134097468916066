@import "styles/_variables.scss";

.header {
  width: calc(100% + 160px);
  height: 70px;
  display: flex;
  justify-content: space-between;
  margin: 0 -80px $margin-m;
  border-bottom: 1px solid $color-border-input;
}

.headerLeft {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.headerRight {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
}

.buildingSelectButton {
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-touched;
  border-radius: 5px;
  font-size: $font-size-2xs;
  color: $color-white;
  white-space: break-spaces;
  text-align: center;
  cursor: pointer;
}

.buildingsCount {
  margin-left: $margin-s;
  font-size: $font-size-l;
  font-weight: $font-weight-medium;
  color: $color-gray-80;
}

.company {
  font-size: $font-size-2xl;
  font-weight: $font-weight-semi-bold;
  color: $color-cta-orange;
}
