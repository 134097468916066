@import "styles/_variables.scss";

.input_label {
  font-size: $font-size-2xs;
  line-height: 15px;
  letter-spacing: 0.025em;
  margin: $margin-xs;
  height: 16px;
  color: $color-input-txt;
  white-space: nowrap;
}
