@import "styles/_variables.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  width: 800px;
  max-width: 100vw;
  min-width: 200px;
  margin: 0;
}

.header {
  font-family: $font-family-default;
  font-style: normal;
  color: $color-header;
  font-weight: $font-weight-bold;
  font-size: $font-size-8xl;
  line-height: 41px;
  margin-bottom: $margin-s;
}

.content {
  display: flex;
  justify-content: space-between;
  margin-top: $margin-2xl;
}

.qr_container {
  text-align: center;
  color: $color-input-label;
  font-size: $font-size-xs;
  font-weight: $font-weight-regular;
  line-height: 24px;
  margin-bottom: $margin-l;
}

.logo_image {
  width: auto;
  height: 50px;
  margin-bottom: $margin-s;
}

.qr_image {
  width: auto;
  height: 300px;
}

.skip_link {
  position: fixed;
  bottom: 52px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: $color-cta;
  cursor: pointer;
}
