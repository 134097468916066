@import "styles/_variables.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  width: 540px;
  max-width: 100vw;
  min-width: 200px;
  margin-bottom: 80px;
}

.header {
  font-family: $font-family-default;
  font-style: normal;
  color: $color-header;
  font-weight: $font-weight-medium;
  font-size: $font-size-8xl;
  line-height: 41px;
  margin-bottom: $margin-s;
}

.txt_container {
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 395px;
  color: $color-input-label;
  font-size: $font-size-s;
  font-weight: $font-weight-regular;
  line-height: 18px;
  margin-bottom: ($margin-xl * 2);
}

.spacing {
  margin-top: ($margin-l * 2);
}
