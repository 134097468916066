@use 'sass:math';
@import "styles/_variables.scss";

.button_section {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: $margin-l $margin-xl $margin-l 0;
}

.btn_separator {
  margin-right: math.div($margin-m, 2);
}

.delete_txt {
  width: 400px;
  margin-top: $margin-2xl;
  font-size: $font-size-xs;
  line-height: 22px;
  color: $color-input-txt;
}

.spacing_txt {
  margin-top: $margin-s;
}

.isLoading {
  width: 400px;
  margin-top: 70px;
}

.info {
  width: 400px;
  margin-top: 150px;
  font-size: $font-size-xs;
}

.info2 {
  width: 400px;
  margin-top: $margin-2xs;
  font-size: $font-size-xs;
}
