@use 'sass:math';
@import "styles/_variables.scss";

.contentWrapper {
  margin-top: $margin-l;
}

.contained_cell {
  margin-top: math.div($margin-m, 2);
  width: auto;
  background: $color-white;
  border: 1px solid $color-grey-600;
  box-sizing: border-box;
  border-radius: 6px;
  flex: 1;
  margin: 5px;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footContentItem {
  padding: 25px;
  display: flex;
  align-items: center;
}

.callAction {
  color: $color-link;
  margin-right: 5px;
}

.icon {
  width: 25px;
  height: 25px;
  margin-right: 20px;
}
