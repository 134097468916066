@import "styles/_variables.scss";

.container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
}

.main {
  position: relative;
  padding: 0 ($padding-xl * 2) ($padding-l * 2) ($padding-xl * 2);
  width: calc(100vw - 72px);
  height: 100vh;
  flex: 1;
  overflow-y: auto;
}
