@import "styles/_variables.scss";

.dropdown {
  flex-basis: 200px;
  margin-right: 7%;
}

.box_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 6px;
}

.txt_email {
  width: 52%;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-label;
  padding-left: 110px;
  padding-right: 50px;
  word-break: break-all;
  text-align: left;
}

.maintainer_icon {
  width: 25%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-weight: $font-weight-medium;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-touched;
}

.icon_menu {
  width: 16px;
  height: 16px;
  margin-right: $margin-2xs;
}

.user_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: 300px;
  justify-content: space-between;
  border-radius: 6px;
}

.date {
  width: 22%;
  font-size: $font-size-xs;
  line-height: 16px;
  padding-left: 10px;
  color: $color-gray-40;
}
