@use 'sass:math';
@import "styles/_variables.scss";

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.btn_containerRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
  cursor: pointer;
}

.btn_containerRight_open {
  @extend .btn_containerRight;
  background: $color-grey-20;
}

.title {
  display: flex;
  align-items: center;
}

.icon {
  display: flex;
  width: 50px;
  min-width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  margin-right: $margin-xs;
}

.attachments_explorer_button {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .attachments_explorer_icon {
    width: 14px;
    height: 14px;
  }
}

.icon_attached {
  width: 18px;
  height: 26px;
  margin: $margin-s;
}

.icon_trash {
  width: 11px;
  height: 14px;
  margin-right: $margin-s;
  cursor: pointer;
}

.icon_eye {
  width: 14px;
  height: 18px;
  margin-right: $margin-s;
  cursor: pointer;
}

.description_tooltip {
  display: none;
  position: absolute;
  bottom: 40px;
  left: 25px;
  max-width: 400px;
  padding: $padding-2xs;
  background: rgba($color-cta-orange, 0.6);
  border-radius: 4px;

  &:hover {
    background: rgba($color-cta-orange, 0.6);
  }
}

.text_area {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background: $color-grey-200;

    .description_tooltip {
      display: flex;
    }
  }
}

.left {
  display: flex;
  flex: 1;
  align-items: center;
}

.fileNameContainer {

}

.file_name {
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
  font-weight: $font-weight-medium;
}

.date {
  font-size: $font-size-xs;
  line-height: 14px;
  color: $color-grey-20;
  margin-right: 30px;
}
