@use 'sass:math';
@import "styles/_variables.scss";

.width {
  width: 392px;
}

.row {
  padding-top: $padding-s;
}

.list_row {
  display: flex;
  align-items: flex-end;
  gap: $padding-xs;
}

.asset_name {
  font-weight: bold;
}

.asset_path {
  font-size: $font-size-xs;
  color: $color-grey-50;
}

.row_error {
  color: $color-error;
  font-size: $font-size-xs;
}

.modal {
  flex-basis: 876px;
}


@media (min-width: 767px) {
  .modal {
    min-width: 700px;
  }
}

@media (min-width: 340px) {
  .modal {
    min-width: 300px;
    margin-left: 50px;
  }
  .width {
    min-width: 300px;
  }
}
