@import "styles/_variables.scss";

.panel {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.building_top_bar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.building_buttons {
  display: flex;

  button {
    margin-right: 15px;
  }
}

.building_filters {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .building_filter {
    display: flex;
    align-items: center;
  }

  .checkbox {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background-color: $color-white;
    border: 1px solid $color-border-input;
    padding: 9px;
    border-radius: 8px;
    display: inline-block;
    position: relative;
    cursor: pointer;
  }

  .checkbox:checked {
    background-color: $color-white;
    border: 2px solid $color-cta-orange;
    color: #99a1a7;
  }

  .checkbox:checked:after {
    content: '\2714';
    font-size: 16px;
    position: absolute;
    top: 0;
    left: 3px;
    color: $color-cta-orange;
  }

  .input_label_checkbox {
    font-weight: $font-weight-medium;
    font-size: $font-size-2xs;
    line-height: 16px;
    padding: $padding-xs;
    color: $color-input-txt;
    cursor: pointer;
  }
}

.assets_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: $color-grey-500;
  font-size: $font-size-2xs;
  line-height: 14px;
  text-transform: uppercase;
  color: $color-gray-40;
  margin-bottom: $margin-xs;
  margin-top: $margin-s;
}

.txt1 {
  margin-left: 60px;
  flex-basis: 330px;
}

.txt2 {
  flex: 1;
  text-align: start;
}

.txt3 {
  flex: 0;
}

.txt4 {
  margin-right: 10px;
}

.accordion_nested {
  margin-bottom: 0px;
  background: $color-white;
  border-right: none;
  border-left: none;
  border-bottom: none;
  position: relative;
}

.accordion_top {
  border-radius: 6px;
}

.accordion_header_nested > :first-child {
  height: 100%;
  border-left: 1px solid $color-border-input;
}

.noLicense {
  background-color: red;
}

.hiddenReloadButton {
  position: fixed;
  width: 72px;
  height: 72px;
  top: 92px;
  left: -0px;
  background: transparent;
  z-index: 2;
  cursor: pointer;
}

@media (max-height: 665px) {
  .hiddenReloadButton {
    height: 42px;
    top: 62px;
  }
}
