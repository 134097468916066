@use 'sass:math';
@import "styles/_variables.scss";

.react-checkbox-tree {
  display: flex;
  flex-direction: row-reverse;
  font-size: $font-size-s;

  > ol {
    flex: 1 1 auto;
  }

  ol {
    margin: 0;
    padding-left: 0;
    list-style-type: none;

    ol {
      padding-left: $padding-m;
    }
  }

  label {
    margin-bottom: 0;
    cursor: pointer;

    &:hover {
      background: $color-cta-light-orange;
    }

    &:active {
      background: $color-cta-light-orange;
    }
  }

  &:not(.rct-native-display) input {
    display: none;
  }
}

.rct-text {
  display: flex;
  align-items: center;
}

.rct-collapse,
.rct-node-icon {
  padding: 8px 5px;
  * {
    display: inline-block;
    margin-top: 0;
    width: 14px;
  }
}

.rtc-selected-asset {
  background: $color-cta-light-orange;
}

.rct-collapse {
  border: 0;
  background: none;
  line-height: normal;
  color: inherit;
  padding-right: math.div($padding-m, 2);
  margin-bottom: math.div($padding-2xs, 2);
  font-size: $font-size-2xs;
}

.rct-title {
  padding-left: math.div($padding-m, 2);
}

.rct-icon {
  display: inline-block;
  text-align: center;
  text-rendering: auto;
  font-weight: normal;
  font-variant: normal;
  font-style: normal;
}
