@use 'sass:math';
@import "styles/_variables.scss";

.legendWrapper {
  width: 270px;
  margin-top: math.div($margin-m, 2);
  background: $color-white;
  border: 1px solid $color-grey-600;
  box-sizing: border-box;
  border-radius: 6px;
  flex: 1;
  margin: 5px;
  padding: 5px 15px;
}

.line {
  display: flex;
  align-items: center;
  margin: 7px 0;
}

.amount {
  font-size: $font-size-xl;
  margin-right: 5px;
}

.amountPercentage {
  margin-top: 5px;
  color: $color-grey-700;
  font-size: $font-size-xs;
}

.square {
  width: 12px;
  height: 12px;
  background-color: $color-info;
  border-radius: 3px;
  margin-right: 5px;
}
.status {
  font-weight: 600;
  font-size: 15px;
}

.indicatorUp {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid $color-info;
  margin-right: 5px;
}

.indicatorDown {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #f00;
  margin-right: 5px;
}

.numberUp {
  color: $color-info;
  margin-right: 3px;
}

.numberDown {
  color: $color-error;
  margin-right: 3px;
}

.text {
  color: $color-grey-800;
}

.cannotBeDone {
  background-color: #d2042d;
}

.withoutDiscrepancies {
  background-color: #64b90c;
}

.withDiscrepancies {
  background-color: #ee7203;
}
