@import "styles/_variables.scss";

.tooltip {
  display: none;
  position: absolute;
  bottom: 60%;
  right: 50%;
  width: 180px;
  padding: $padding-2xs;
  background: rgba($color-cta-orange, 0.6);
  border-radius: 4px;
  text-align: center;
  overflow: visible;
  z-index: 2000;
}

.wrapper {
  position: relative;

  &:hover .tooltip {
    display: flex;
  }
}

.base {
  padding: $padding-xs;
}

.button {
  border-radius: 8px;
  border: 1px solid;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.ripple {
  width: 20px;
  height: 20px;
  position: absolute;
  background: #f7c392;
  display: block;
  content: "";
  border-radius: 9999px;
  opacity: 1;
  animation-name: ripple;
  animation-duration: 850ms;
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(45);
    opacity: 0.375;
  }
  100% {
    transform: scale(70);
    opacity: 0;
  }
}

.button > .content {
  position: relative;
  z-index: 2;
}

.primary {
  @extend .button;
  background: $color-cta;
  color: $color-white;
  border: 1px solid $color-cta;
}

.ghost {
  @extend .button;
  background: $color-white;
  color: $color-gray-150;
  border: 0.7px solid $color-gray-30;
  border-radius: 6px;
}

.orange {
  @extend .button;
  background: $color-cta;
  color: $color-white;
  border: 1px solid $color-cta-orange;
  background: $color-cta-orange;
}

.disabled {
  @extend .button;
  background: $color-gray-30;
  color: $color-white;
  border: 1px solid $color-gray-30;
  background: $color-gray-30;
  cursor: not-allowed;
}

.s {
  min-width: 100px;
  font-size: $font-size-xs;
  font-weight: $font-weight-regular;
  line-height: 15px;
  padding: 10px 24px;
  .icon {
    width: 18px;
    height: 18px;
  }
}
//assets buttons
.S {
  min-width: 170px;
  font-size: $font-size-xs;
  font-weight: $font-weight-regular;
  line-height: 15px;
}

.m {
  min-width: 300px;
  font-size: $font-size-xs;
  font-weight: $font-weight-regular;
  line-height: 18px;
}

.l {
  min-width: 100%;
  font-size: $font-size-s;
  font-weight: $font-weight-regular;
  line-height: 18px;
  padding: $padding-s;
}
