@import "styles/_variables.scss";

.column {
  display: flex;
  flex-direction: column;
}

.filters_section {
  margin-top: $margin-m;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.row {
  display: flex;
  flex-direction: row;
  padding-top: $padding-xs;
}
