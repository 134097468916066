@use 'sass:math';
@import "styles/_variables.scss";

.mainWrapper {
  background-color: rgba($color-cta-orange, $alpha: 0.1);
  padding: 10px;
}

.width {
  width: 392px;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.row_section {
  @extend .row;
  align-items: center;
}

.red {
  color: $color-error;
}

.modal {
  flex-basis: 876px;
}

.start_row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $margin-s;
}

.buttonRemoveWrapper {
  margin-left: $margin-s;
}

.property_input {
  display: flex;
  align-items: center;
}

.label_right {
  margin-right: $margin-s;
}

.add_txt {
  font-size: $font-size-s;
  line-height: 18px;
  color: $color-cta-orange;
  cursor: pointer;
}

.txt {
  font-weight: $font-weight-regular;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
  margin: math.div($margin-m, 2) math.div($margin-xl, 2) 0
    math.div($margin-xl, 2);
}

.txt1 {
  font-weight: $font-weight-regular;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-cta-orange;
  margin: math.div($margin-m, 2) 0 0 0;
  cursor: pointer;
}

.title {
  font-weight: $font-weight-medium;
  font-size: $font-size-s;
  line-height: 18px;
  color: $color-input-txt;
  margin-bottom: $margin-s;
}

.margin_bottom {
  margin-bottom: $margin-s;
}

.add_btn {
  border: 1px solid $color-cta-orange;
  border-radius: 4px;
  display: flex;
  align-self: center;
  padding: 10px;
  background: $color-cta-orange;
  margin-left: math.div($margin-m, 2);
  margin-top: 3px;
  color: #fff;
  cursor: pointer;
}

.add_icon {
  width: 14px;
  height: 14px;
}

.units_container {
  margin-left: math.div($margin-m, 2);
  width: 25%;
}

.cursor {
  cursor: pointer;
}

.rule_dropdown {
  width: 291px;
  margin-bottom: $margin-s;
}

.delete_rules {
  margin: math.div($margin-m, 2) 0 0 0;
  margin-left: 16px;
}

.propertyContainer {
  padding: 10px 20px;
  background-color: rgb(223, 255, 244);
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  position: relative;

  .removePropertyBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  .unit {
    margin-bottom: $margin-xs;
  }
}

.accordion_header {
  height: 40px;
  flex: auto;
}

@media (min-width: 767px) {
  .modal {
    min-width: 700px;
  }
}

@media (min-width: 340px) {
  .modal {
    min-width: 300px;
    margin-left: 50px;
  }
  .width {
    min-width: 300px;
  }
}
