@use 'sass:math';
@import "styles/_variables.scss";

.box_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid $color-grey-600;
  justify-content: space-between;
  border-radius: 6px;
}

.user_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: 300px;
  justify-content: space-between;
  border-radius: 6px;
}

.dropdown {
  flex-basis: 200px;
}

.img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: math.div($margin-m, 2);
}

.txt_name {
  //display: flex;
  //align-items: center;
  height: 100%;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
  flex-basis: 230px;
  word-break: break-word;
}

.txt_email {
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-label;
  min-width: 250px;
}

.no_img {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-grey-60;
  width: 30px;
  height: 30px;
  font-weight: $font-weight-medium;
  font-size: $font-size-xs;
  border-radius: 50%;
  line-height: 16px;
  color: $color-white;
  padding: math.div($padding-m, 2);
  margin: math.div($margin-m, 2);
}

.checkbox_container {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: $margin-2xs;
}

.checkbox {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  background-color: $color-white;
  border: 1px solid $color-border-input;
  padding: 9px;
  border-radius: 8px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.checkbox:checked {
  background-color: $color-white;
  border: 2px solid $color-cta-orange;
  color: #99a1a7;
}

.checkbox:checked:after {
  content: '\2714';
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 3px;
  color: $color-cta-orange;
}

.checkbox:disabled {
  background-color: $color-grey-20;
  cursor: default;
}

.notification {
  width: fit-content;
  font-size: $font-size-3xs;
  line-height: 10px;
  border-radius: 4px;
  padding: $padding-2xs;
  margin-top: $margin-2xs;
  //margin-left: $margin-xs;
  text-align: center;
}

.notification_red {
  @extend .notification;
  color: $color-white;
  background: $color-error;
  border: 1px solid $color-error;
}

.notification_white {
  @extend .notification;
  color: $color-dark-black;
  background: $color-white;
  border: 1px solid $color-input-txt;
}

.notification_orange {
  @extend .notification;
  color: $color-white;
  background: $color-cta-orange;
  border: 1px solid $color-cta-orange;
}

.no_notifcation {
  margin-left: 110px;
}

.date {
  font-size: $font-size-xs;
  line-height: 16px;
  text-align: right;
  color: $color-gray-40;
}

.meatball {
  margin-right: $margin-s;
}
