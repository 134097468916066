@import "styles/_variables.scss";

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $margin-xs;
  align-items: flex-start;
}

.date_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.date_label {
  margin-right: 10px;
  font-size: $font-size-xs;
}

.wrapper {
  width: fit-content;
  height: 35px;
}

.react-datepicker__input-container,
.react-datepicker__input-container input {
  height: 60px;
}

.assetOptionLabel {

  .assetOptionName {

  }

  .assetOptionPath {
    font-size: $font-size-2xs;
    line-height: 16px;
    color: $color-grey-50;
  }
}

.nearly_missed_filter {
  height: 36px;
  display: flex;
  align-items: center;
}

.checkbox {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  background-color: $color-white;
  border: 1px solid $color-border-input;
  padding: 9px;
  border-radius: 8px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.checkbox:checked {
  background-color: $color-white;
  border: 2px solid $color-cta-orange;
  color: #99a1a7;
}

.checkbox:checked:after {
  content: '\2714';
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 3px;
  color: $color-cta-orange;
}

.input_label_checkbox {
  font-weight: $font-weight-medium;
  font-size: $font-size-2xs;
  line-height: 16px;
  padding: $padding-xs;
  color: $color-input-txt;
  cursor: pointer;
}
