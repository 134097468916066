@use 'sass:math';
@import "styles/_variables.scss";

.row {
  display: flex;
  flex-direction: row;
  border: 1px solid $color-grey-600;
  border-radius: 6px;
  margin-bottom: $margin-xs;
  align-items: center;
}

.visible_for_sub_companies {
  background: $color-cta-light-orange;
}

.title {
  font-size: $font-size-s;
  line-height: 16px;
  margin: $margin-s;
  color: $color-input-txt;
  font-weight: $font-weight-regular;
  flex: 1;
  max-width: 150px;
  word-wrap: break-word;
  flex-basis: 170px;
}

.title_deprecated {
  @extend .title;
  text-decoration: line-through;
}

.meatball {
  margin-right: $margin-s;
}

.date {
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-gray-40;
  flex: 1;
  text-align: end;
  margin-right: $margin-s;
}

.separator {
  border-bottom: 1px solid $color-grey-600;
  width: 95%;
  align-self: center;
}

.column_right {
  flex-grow: 2;
  font-weight: $font-weight-medium;
  font-size: $font-size-2xs;
  line-height: 14px;
  color: $color-gray-40;
}

.right_container {
  border: 1px solid $color-grey-600;
  box-sizing: border-box;
  border-radius: 6px;
  padding: $padding-m;
}
