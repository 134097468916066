
@use 'sass:math';
@import "styles/_variables.scss";

.row {
  display: flex;
  flex-direction: row;
}

.condition_dropdown {
  justify-content: center;
  width: 86px;
  margin: 0 math.div($margin-m, 2);
}

.delete_btn {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: math.div($margin-m, 2);
}

.spacer_top {
  margin-top: math.div($margin-xl, 2);
}

.box {
  border: 1px solid $color-grey-600;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
  align-items: flex-start;
  padding: 10px;
}

.contains_txt {
  @extend .box;
  align-self: center;
  width: 89px;
  margin: 0 math.div($margin-m, 2);
}

.date_section {
  display: flex;
  justify-content: row;
  align-items: center;
}

.select {
  margin: 0 math.div($margin-m, 2);
  width: 190px;
}

.delete_icon {
  cursor: pointer;
}
