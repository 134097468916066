@import "styles/_variables.scss";

.properties {
  width: 100%;
  padding: $padding-s;
  background: $color-cta-light-bl;
}

.container {
  display: flex;
  justify-content: flex-end;
}

.button {
  margin: 5px;
  margin-bottom: 0;
}
