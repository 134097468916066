@use 'sass:math';
@import "styles/_variables.scss";

.container {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.label {
  margin: 0 $margin-2xs;
}
