@use 'sass:math';
@import "styles/_variables.scss";

.legendWrapper {
  width: 250px;
  margin: 5px;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 6px;
  flex: 1;
}

.square {
  display: inline-block;
  width: 12px;
  min-width: 12px;
  height: 12px;
  background-color: $color-info;
  border-radius: 3px;
  margin-right: 5px;
  margin-top: 2px;
}

.status {
  width: 200px;
  font-weight: 600;
  font-size: $font-size-xs;
}

.line {
  display: flex;
  align-items: flex-start;
  margin: 5px 0;
}

.amount {
  color: $color-grey-800;
  font-size: $font-size-xs;
}

.amountPercentage {
  margin: 0 5px;
  font-size: $font-size-xs;
  white-space: nowrap;
}

.indicatorUp {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid $color-info;
  margin-right: 5px;
}

.indicatorDown {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #f00;
  margin-right: 5px;
}

.numberUp {
  color: $color-info;
  margin-right: 3px;
}

.numberDown {
  color: $color-error;
  margin-right: 3px;
}

.text {
  color: $color-grey-800;
}
