.sensorChartContainer {
  height: 300px;
  flex: 1;
}

.customTooltip {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  padding: 3px 20px;
}

.sensorWrapper {
  width: 100%;
  height: 500px;
  position: relative;
}

.padlock {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0px;
  bottom: 0px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 20px;
  height: 20px;
}

.sensorName {
  margin-left: 10px;
}

.text {
  font-weight: 700;
}
