@use 'sass:math';
@import "styles/_variables.scss";

.row {
  display: flex;
  flex-direction: row;
}

.start_row {
  @extend .row;
  display: flex;
  justify-content: flex-start;
}

.button {
  padding-bottom: 15px;
}

.buttonRemoveWrapper {
  padding-top: 5px;
  align-self: center;
}

.red {
  color: $color-error;
}

.dropdown {
  justify-content: center;
  width: 200px;
  margin: 0 math.div($margin-m, 2);
}

.condition_row {
  @extend .row;
  align-items: center;
}

.condition_txt {
  font-weight: $font-weight-medium;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
}

.condition_dropdown {
  justify-content: center;
  width: 86px;
  margin: 0 math.div($margin-m, 2);
}

.btn_top {
  margin-left: math.div($margin-3xl, 2);
}

.value_txt {
  border: 1px solid $color-grey-600;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
  align-items: flex-start;
  width: 190px;
  padding: 10px;
}

.contains_txt {
  @extend .value_txt;
  width: 89px;
  margin: 0 math.div($margin-m, 2);
}

.perform_dropdown {
  width: 291px;
}

.perform_txt {
  @extend .condition_txt;
  margin: $margin-l 0;
}

.spacer_checkbox {
  margin-bottom: $margin-l;
}

.label_right {
  font-size: $font-size-2xs;
  line-height: 15px;
  letter-spacing: 0.025em;
  margin: $margin-xs $margin-xs $margin-xs $margin-s;
  height: 16px;
}

.trash {
  display: flex;
  align-items: flex-start;
  margin-left: auto;
  cursor: pointer;
}

.icon_box {
  border: 1px solid $color-grey-600;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 12px;
  margin-left: math.div($margin-m, 2);
}

.icon {
  height: 14px;
  width: 11px;
}

.button_section {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: $margin-l $margin-xl $margin-l 0;
}

.btn_spacer {
  margin-right: math.div($margin-m, 2);
}

.info_spacer {
  margin-bottom: $margin-m;
}

.number_input {
  margin-left: $margin-m;
  margin-bottom: $margin-m;
}

@media (max-width: 767px) {
  .row {
    flex-direction: column;
  }
}
