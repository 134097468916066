@import "styles/_variables.scss";

.container {
    position: relative;
    display: inline-flex;
    user-select: none;
    justify-content: center;
    -webkit-tap-highlight-color: transparent;
    max-width: 100%;
}

.tooltip_content {
    color: $color-white;
    position: absolute;
    height: fit-content;
    z-index: 300;
    padding: $padding-2xs $padding-xs;
    font-size: $font-size-s;
    background: $color-black;
    animation: fadeIn ease-in-out 0.65s;
    max-width: 100vw;
    &.s {
        min-width: 300px;
    }

    &.m {
        min-width: 410px;
    }
}

.arrow {
    position: absolute;
    width: 15px;
    height: 15px;
}

.right {
    top: -14px;
    left: 167px;
    .arrow {
        left: -9px;
        top: 15px;
        transform: rotate(315deg);
        border-top: 1px solid $color-grey-700;
        border-right: none;
        border-left: 1px solid $color-grey-700;
        border-bottom: none;
        background: $color-white;
    }
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.white {
    bottom: -33px;
    background: $color-white;
    border-radius: 10px;
    color: $color-black;
    border: 1px solid $color-grey-700;
}



