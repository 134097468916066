@use 'sass:math';
@import "styles/_variables.scss";

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.start_row {
  @extend .row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.red {
  color: $color-error;
}

.separator {
  border: 0.5px solid $color-grey-600;
}
.dropzone_container {
  min-height: auto;
}

.dropdown {
  justify-content: center;
  width: 200px;
  margin: 0 math.div($margin-m, 2);
}

.dropdown_config {
  justify-content: center;
  width: 392px;
  margin: 0 0 30px 0;
}

.add_btn {
  border: 1px solid $color-cta-orange;
  border-radius: 4px;
  display: flex;
  align-self: center;
  padding: 10px;
  background: $color-cta-orange;
  margin-left: math.div($margin-m, 2);
  cursor: pointer;
}

.add_icon {
  width: 14px;
  height: 14px;
}

.checkbox_required {
  display: flex;
  align-items: center;
}

.section_name {
  display: flex;
  align-items: center;
  background: $color-cta-light-bl;
  font-weight: $font-weight-medium;
  font-size: $font-size-s;
  line-height: 18px;
  color: $color-input-txt;
  padding: 0 0 $padding-s $padding-2xs;
}

.delete_btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.delete_icon {
  margin-left: 12px;
}

.add_section {
  font-size: $font-size-2xs;
  line-height: 14px;
  color: $color-cta-orange;
  margin-bottom: $margin-xl;
}

.border {
  border-right: 1px solid $color-cta-orange;
  margin-left: $margin-2xs;
  padding-right: $padding-s;
}

.right {
  margin-left: math.div($margin-xl, 2);
}

.date_section {
  display: flex;
  justify-content: row;
  align-items: center;
}

.error {
  color: $color-error;
  font-size: $font-size-2xs;
  line-height: 13px;
  font-weight: $font-weight-regular;
  margin-top: $margin-xs;
  margin-left: $margin-2xs;
}

.width_dropdown {
  width: 190px;
  margin-bottom: $margin-l;
}

.width_dropdown2 {
  @extend .width_dropdown;
  margin-left: 20px;
}

@media (max-width: 767px) {
  .modal {
    min-width: 700px;
  }
  .row {
    flex-direction: column;
  }
  .add_btn {
    margin-top: math.div($margin-m, 2);
  }
  .checkbox_required {
    margin-top: math.div($margin-m, 2);
  }
}

@media (min-width: 340px) {
  .modal {
    min-width: 300px;
    margin-left: $margin-3xl;
  }
  .width {
    min-width: 300px;
  }
}
