@use 'sass:math';
@import "styles/_variables.scss";

.container {
  //max-width: 200px;
  display: flex;
  flex-direction: column;
  justify-self: center;
  position: relative;
  padding-left: $padding-2xs;
  cursor: pointer;
  user-select: none;
}

.txt {
  padding-left: $padding-m;
  font-size: $font-size-xs;
  user-select: none;
  padding-bottom: math.div($padding-xl, 2);
  color: $color-input-txt;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: -2px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid $color-grey-600;
  background: $color-white;
  border-radius: 50%;
}

.container input:checked ~ .checkmark {
  background: $color-white;
  border: 1px solid $color-touched;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.checkmark::after {
  content: "";
  display: flex;
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark::after {
  display: block;
}

.container .checkmark::after {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: $color-touched;
}

//disabled
.checkmark2 {
  position: absolute;
  top: -2px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid $color-grey-700;
  background: $color-white;
  border-radius: 50%;
}

.container input:checked ~ .checkmark2 {
  background: $color-white;
  border: 1px solid $color-grey-700;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.checkmark2::after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark2::after {
  display: block;
}

.container .checkmark2::after {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: $color-grey-700;
}

.container_vertical {
  @extend .container;
  max-width: unset;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $margin-s;

  .txt {
    padding-bottom: $padding-2xs;
  }
}
