@import "styles/_variables.scss";

.row {
  display: flex;
  flex-direction: row;
  border: 1px solid $color-grey-600;
  border-radius: 6px;
  margin-bottom: $margin-xs;
  align-items: center;
}

.visible_for_sub_companies {
  background: $color-cta-light-orange;
}

.descriptionTooltip {
  display: none;
  position: absolute;
  bottom: 10px;
  left: 15px;
  max-width: 250px;
  padding: $padding-2xs;
  background: rgba($color-cta-orange, 0.6);
  border-radius: 4px;
}

.title {
  position: relative;
  font-size: $font-size-s;
  line-height: 16px;
  margin: $margin-s;
  color: $color-input-txt;
  font-weight: $font-weight-regular;
  flex: 1;

  &:hover .descriptionTooltip {
    display: flex;
  }
}

.title2 {
  position: relative;
  font-size: $font-size-s;
  line-height: 16px;
  margin: $margin-s;
  text-decoration: line-through;
  color: $color-gray-40;
  font-weight: $font-weight-regular;
  flex: 1;

  &:hover .descriptionTooltip {
    display: flex;
  }
}

.deprecated {
  text-decoration: line-through;
  font-weight: bold;
}

.date {
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-gray-40;
  flex: 1;
  text-align: end;
  margin-right: $margin-s;
}

.group {
  align-self: center;
}

.meatball {
  margin-right: $margin-s;
}
