@use 'sass:math';
@import "styles/_variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  min-width: 200px;
  width: 392px;
  padding: 0 $padding-xs;
  min-height: 36px;
  border: 1px solid $color-grey-600;
  box-shadow: $shadow-input;
  border-radius: 6px;
  cursor: pointer;
}

.wrapper2 {
  display: flex;
  align-items: center;
  min-width: 200px;
  width: 392px;
  padding: 0 $padding-xs;
  min-height: 36px;
  border: 1px solid $color-grey-600;
  box-shadow: $shadow-input;
  border-radius: 6px;
  cursor: pointer;
  background: $color-grey-600;
  opacity: 0.35;
}


.tagsinput--focused {
  border: 1px solid $color-cta-orange;
}

.tagsinput_tag {
  border-radius: 4px;
  border: 1px solid $color-grey-600;
  display: inline-block;
  font-size: $font-size-2xs;
  font-weight: $font-weight-regular;
  margin-bottom: $margin-2xs;
  margin-right: $margin-xs;
  padding: $padding-2xs;
}

.tagsinput_tag:nth-child(odd) {
  background: $color-light-blue;
  color: $color-dark-black;
}

.tagsinput_tag:nth-child(even) {
  background: $color-cta-bl;
  color: $color-white;
}

.tagsinput_remove {
  cursor: pointer;
  font-weight: $font-weight-bold;
}

.tagsinput_tag a::before {
  content: " ×";
}

.tagsinput_input {
  background: transparent;
  border: 0;
  color: $color-input-txt;
  font-size: $font-size-xs;
  font-weight: $font-weight-regular;
  margin-bottom: $margin-2xs;
  margin-top: math.div($margin-2xs, 2);
  outline: none;
  padding: $padding-2xs;
  width: 80px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: $margin-l;
}

.delete_icon {
  margin-left: 12px;
  cursor: pointer;
}
