@import "styles/_variables.scss";

.title_row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: $font-weight-medium;
  font-size: $font-size-2xs;
  line-height: 14px;
  color: $color-input-label;
}

.title_row1 {
  @extend .title_row;
  margin-bottom: 4px;
}

.title_name {
  display: flex;
  flex-basis: 300px;
  padding-left: $padding-3xl;
}

.title_name1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 38%;
}

.title_email {
  display: flex;
  flex-basis: 250px;
}

.title_role {
  display: flex;
  flex-basis: 200px;
}

.title_added {
  display: flex;
  flex-basis: 93px;
}

.title_bool {
  display: flex;
  flex-basis: 250px;
}

.title_name_maintainer {
  width: 328px;
  display: flex;
  padding-left: $padding-3xl;
}

.title_email_maintainer {
  width: calc(60% - 328px);
  padding-left: 110px;
  padding-right: 10px;
}

.title_role_maintainer {
  width: 20%;
  padding-right: 10px;
}

.title_added_maintainer {
  width: calc(20% - 50px);
  padding-right: 10px;
}

.title_action_maintainer {
  width: 50px;
}
