@use 'sass:math';
@import "styles/_variables.scss";

.row {
  display: flex;
  flex-direction: row;
}

.file_row {
  @extend .row;
  flex-wrap: wrap;
  margin-top: math.div($margin-m, 2);
}

.drag_container {
  border: 1px solid $color-grey-600;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 6px 84px;
  cursor: pointer;
}

.text {
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
}

.icon_drag {
  margin-right: $margin-xs;
}


.underline {
  text-decoration: underline;
  color: $color-cta-bl;
}

.error {
  color: $color-error;
  font-size: $font-size-2xs;
  line-height: 13px;
  font-weight: $font-weight-regular;
  margin-top: $margin-xs;
  margin-left: $margin-2xs;
}
