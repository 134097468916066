@import "styles/_variables.scss";

.wrapper {
  display: flex;
  margin: 10px 0;
  padding: 10px;
  width: 392px;
  background-color: $color-grey-20;
  height: 50px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
}

.contentWrapper {
  display: flex;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.iconContainer {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.arrow {
  margin-right: 5px;
}

.estimatedText {
  color: #aaaaaa;
  font-size: $font-size-xs;
}
