@import "styles/_variables.scss";

.asset_details_modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 72px;
  right: 0;
  z-index: 20;
  background: $color-white;
  padding: ($padding-l * 2) ($padding-xl * 2);
  overflow-y: auto;
}
