@import "styles/_variables.scss";

.filterWrapper {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 16px;
  cursor: pointer;

  .filter {

  }
}
