@import "styles/_variables.scss";

.row {
  display: flex;
  flex-direction: row;
}

.input_search {
  margin-bottom: $margin-m;
  width: 392px;
}

.dropdown_role {
  width: 190px;
  margin: 0 $margin-m;
}
