// margins
$margin-2xs: 4px;
$margin-xs: 8px;
$margin-s: 16px;
$margin-m: 24px;
$margin-l: 32px;
$margin-xl: 40px;
$margin-2xl: 48px;
$margin-3xl: 54px;

// paddings
$padding-2xs: 4px;
$padding-xs: 8px;
$padding-s: 16px;
$padding-m: 24px;
$padding-l: 32px;
$padding-xl: 40px;
$padding-2xl: 48px;
$padding-3xl: 54px;

// colors theme
$color-cta: #0a49a7;
$color-cta-bl: #01b6cb;
$color-cta-bl-dark: #337ffb;
$color-cta-light-bl: #f8faff;
$color-cta-exLight-bl: #f0f8e7;
$color-cta-bg: #f1f8ff;
$color-cta-light-rd: #feedeb;
$color-cta-rd: #d2042d;
$color-cta-light-orange: #fff8f1;
$color-cta-orange: #ee7203;

// greyF scale
$color-dark-black: #000000;
$color-black: #191919;
$color-light-black: #0c152f;
$color-gray-70: #363030;
$color-grey-100: #424d57;
$color-gray-150: #575756;
$color-grey-50: #8992a3;
$color-grey-800: #8c8c8c;
$color-gray-80: #9e9fa1;
$color-gray-40: #aaaaaa;
$color-grey-60: #cccccc;
$color-gray-30: #cad0d6;
$color-grey-700: #c9c9c9;
$color-grey-600: #dddddd;
$color-grey-500: #e8e8e8;
$color-grey-450: #eaeaea;
$color-grey-400: #f3f3f3;
$color-grey-20: #f4f4f4;
$color-grey-200: #f8f8f8;
$color-grey-300: #f9f9f9;
$color-white: #ffffff;
$color-light-blue: #d8e6ff;

//assets colors
$asset-1: #d2042d;
$asset-2: #ff7d04;
$asset-3: #f9dc76;
$asset-4: #37833b;
$asset-5: #2264d1;

//text color and sidebar background
$color-header: #3a3a53;
$color-txt: rgba(137, 146, 163, 1);
$color-input-label: #707070;
$color-input-txt: #393939;

// system
$color-error: #d2042d;
$color-error-background: #fff5f5;
$color-link: #2264d1;
$color-touched: #ee7203;
$color-info: #64b90c;

//box shadow

//input and accordion item
$shadow-input: 0px 2px 4px rgba(58, 58, 83, 0.04);

//hovered uploaded
$hovered-upload: rgba(87, 87, 86, 0.7);

//status
$color-active-bg: rgba(25, 25, 29, 0.5);
$color-inactive: #d2042d;

//borders
$color-border-active: #19191d;
$color-border-input: #dde2e6;

//modal background
$modal-overlay-bg: #19191d;

//family
$font-family-default: "Ubuntu", sans-serif;

// font size
$font-size-4xs: 8px;
$font-size-3xs: 10px;
$font-size-2xs: 12px;
$font-size-xs: 14px;
$font-size-s: 16px;
$font-size-m: 18px;
$font-size-l: 20px;
$font-size-xl: 22px;
$font-size-2xl: 24px;
$font-size-3xl: 26px;
$font-size-4xl: 28px;
$font-size-5xl: 30px;
$font-size-6xl: 32px;
$font-size-7xl: 34px;
$font-size-8xl: 36px;
$font-size-9xl: 38px;
$font-size-10xl: 40px;
$font-size-11xl: 42px;
$font-size-12xl: 44px;
$font-size-13xl: 46px;

//weight
$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;
