@use 'sass:math';
@import "styles/_variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  height: 50px;
}

.bigCircle {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-grey-500;
  border-radius: 100%;
  position: relative;
  margin-right: 15px;
}

.smallCircle {
  width: 25px;
  height: 25px;
  background-color: $color-touched;
  border-radius: 100%;
  position: absolute;
  right: -10px;
  bottom: -10px;
}

.bigIcon {
  width: 25px;
  height: 25px;
  color: grey;
}

.welcome {
  font-weight: 700;
  margin-top: 5px;
  font-size: $font-size-4xl;
}

.welcomeTextContainer {
  height: 60px;
  display: flex;
  flex-direction: column;
}
