@import "styles/_variables.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.container_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.link_return {
  cursor: pointer;
}

.path {
  display: flex;
  align-items: center;
  font-size: $font-size-m;
  line-height: 21px;
  color: $color-input-label;
  font-weight: $font-weight-regular;
  margin-left: $margin-s;
  gap: $margin-xs;
}

.title {
  font-weight: $font-weight-medium;
  font-size: $font-size-2xl;
  line-height: 24px;
  color: $color-input-txt;
}

.prototype_info {
  margin: $margin-s 0;

  .prototype_desc {
    color: $color-input-label;
  }
}
