@import "styles/_variables.scss";

.divider {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $color-grey-600;
}

.tab_box {
  font-size: $font-size-s;
  line-height: 18px;
  font-weight: $font-weight-regular;
  color: $color-input-txt;
  padding: $padding-s;
  white-space: nowrap;
  cursor: pointer;
}

.tab_active {
  border-bottom: 4px solid $color-cta-orange;
  font-weight: $font-weight-medium;
  color: $color-cta-orange;
}
