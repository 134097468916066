@import "styles/_variables.scss";

.input_NoIcon {
  height: 50px;
  border: 1px solid $color-grey-600;
  border-radius: 8px;
  font-weight: $font-weight-regular;
  font-size: $font-size-xs;
  line-height: 16px;
  padding-left: $padding-s;
  color: $color-input-txt;
  outline: none;
}

.input_NoIcon:focus {
  outline: none;
  border: 1px solid $color-cta-orange;
}

.input_NoIcon::-ms-reveal {
  display: none;
}

input::placeholder {
  color: $color-gray-40;
  padding-left: 4px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.error {
  border: 1px solid $color-error;
}

.xxs {
  width: 89px;
  height: 38px;
}

.xs {
  width: 140px;
  height: 38px;
}

.s {
  width: 190px;
  height: 38px;
}

.m {
  width: 291px;
  height: 38px;
}

.l {
  width: 392px;
  height: 38px;
}

//input with icon
.main_container {
  display: flex;
  flex-direction: row;
  position: relative;
  flex-grow: 1;
}

.input {
  position: relative;
  flex-grow: 1;
  resize: none;
  overflow: hidden;
  border: 1px solid $color-grey-600;
  border-radius: 8px;
  font-weight: $font-weight-regular;
  font-size: $font-size-xs;
  line-height: 16px;
  padding-left: $padding-m;
  color: $color-gray-40;
  outline: none;
}

.input:focus {
  outline: none;
  border: 1px solid $color-cta-orange;
}

.input::-ms-reveal {
  display: none;
}

.input_icon {
  position: absolute;
  left: 5px;
  width: 15px;
  height: 15px;
  margin: 8px 0 0 4px;
  transform: translate(0%, 30%);
  overflow: visible;
  cursor: pointer;
}

.disabled {
  background: $color-grey-600;
  opacity: 0.45;
}
