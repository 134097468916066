@use 'sass:math';
@import "styles/_variables.scss";

.button_section {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: $margin-l $margin-xl $margin-l 0;
}

.btn_separator {
  margin-left: math.div($margin-xl, 2);
}

.dropdown {
  margin-bottom: $margin-xl;
}

.red {
  color: $color-error;
}

.txt1 {
  font-weight: $font-weight-regular;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
  color: $color-cta-orange;
  margin: math.div($margin-m, 2) 0px 0px 0px;
}

.grouping_assets_access_wrapper {
  width: 100%;
  margin-bottom: $margin-xl;

  .grouping_assets_access_label {
    font-size: $font-size-2xs;
    line-height: 15px;
    letter-spacing: 0.025em;
    margin: $margin-xs;
    height: 16px;
    color: $color-input-txt;
    white-space: nowrap;
  }

  .grouping_assets_access_box {
    width: 100%;
    min-height: 38px;
    display: flex;
    border: 1px solid $color-grey-60;
    border-radius: 4px;

    .grouping_assets_access_input {
      width: calc(100% - 40px);
      display: flex;
      align-items: center;
      padding: $padding-2xs $padding-xs;

      .grouping_assets_access_input_placeholder {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        color: $color-gray-40;
        font-size: $font-size-xs;
        cursor: pointer;
      }

      .grouping_assets_access_input_values {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .grouping_assets_access_input_value {
          height: 19px;
          display: flex;
          align-items: center;
          margin: 2px;
          padding: 0 0 0 $padding-2xs;
          background: $color-light-blue;
          border-radius: 2px;

          .grouping_assets_access_input_value_label {
            font-size: $font-size-2xs;
            font-weight: $font-weight-light;
          }

          .grouping_assets_access_input_value_icon {
            width: 20px;
            height: 19px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 2px;
            cursor: pointer;
          }
        }
      }
    }

    .grouping_assets_access_icon {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
