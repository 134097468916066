@import "../../styles/_variables.scss";

.preconditions_editor {
  background: $color-white;
  margin-bottom: $margin-s;
  border-radius: 5px;
}

.precondition_form {
  background: $color-cta-light-orange;
  padding: $padding-xs;
  margin-bottom: $margin-s;
  border-radius: 5px;
}

.precondition_form_new {
  @extend .precondition_form;
  background: $color-cta-light-rd;
}

.and_separator {
  padding-left: $padding-xs;
  margin-bottom: $margin-s;
  color: $color-input-txt;
}

.add_precondition_button {
  font-size: $font-size-2xs;
  line-height: 14px;
  color: $color-cta-orange;
  margin-bottom: $margin-s;
  cursor: pointer;
}
