@use 'sass:math';
@import "styles/_variables.scss";

.contentWrapper {
  margin-top: $margin-l;
}
.contained_cell {
  margin-top: math.div($margin-m, 2);
  width: auto;
  background: $color-white;
  border: 1px solid $color-grey-600;
  box-sizing: border-box;
  border-radius: 6px;
  flex: 1;
  margin: 5px;
}
.containedHeader {
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: space-between;
  font-size: $font-size-m;
  font-weight: $font-weight-semi-bold;
  background-color: $color-grey-200;
  padding: 15px;
  border-bottom: 1px solid $color-grey-600;
}

.lineChartContainer {
  height: 400px;
}
