@use 'sass:math';
@import "styles/_variables.scss";

.modal {
  max-width: 90vw;
  background-color: $color-white;
  border-radius: 10px;
}

.fullWidth {
  @extend .modal;
  width: 90vw;
  min-width: 90vw;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $color-active-bg;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.header {
  padding-top: $margin-l;
  padding-bottom: $margin-s;
  display: flex;
  flex-direction: row;
}

.title {
  font-weight: $font-weight-medium;
  font-size: $font-size-l;
  line-height: 23px;
  color: $color-input-txt;
  margin-left: $margin-xl;
}
.subtitle {
  font-weight: $font-weight-medium;
  font-size: $font-size-m;
  line-height: 30px;
  color: $color-input-txt;
  margin-left: $margin-xl;
}
.divider {
  border: 0.5px solid $color-grey-600;
  margin: 0px;
}

.delete_title {
  display: flex;
  align-items: center;
}

.delete_category {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border: 1px solid $color-inactive;
  border-radius: 50%;
  color: $color-inactive;
  margin-left: $margin-l;
}

.contact_icon {
  display: flex;
  justify-content: center;
  margin-left: $margin-xl;
}

.title1 {
  font-weight: $font-weight-medium;
  font-size: $font-size-s;
  line-height: 18px;
  color: $color-input-txt;
  margin-left: math.div($margin-m, 2);
}

.icon_delete {
  width: 10px;
  height: 10px;
}

.button_section {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: $padding-s $padding-xl $padding-s 0;
}

.btn_spacer {
  margin-right: math.div($margin-m, 2);
}
