@import 'styles/_variables.scss';

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid $color-grey-600;
    border-radius: 6px;
    margin-bottom: $margin-xs;
    align-items: center;
}

.title {
    font-size: $font-size-s;
    line-height: 16px;
    margin: $margin-s;
    color: $color-input-txt;
    font-weight: $font-weight-regular;
    width: 200px;
    word-wrap: break-word;
}

.date {
    display: flex;
    flex-direction: row;
    font-size: $font-size-xs;
    line-height: 16px;
    color: $color-gray-40;
    margin-right: $margin-s;
}

.create {
    width: 90px;
}

.iconCell {
    flex-basis: 200px;
}

.meatball {
    margin-left: $margin-s;
}
