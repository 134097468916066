@use 'sass:math';
@import "styles/_variables.scss";

.contentWrapper {
  margin-top: $margin-l;
}

.selectionWrapper {
  display: flex;
  align-items: center;
}
.dividerLine {
  height: 50px;
  width: 1px;
  border-left: 1px solid #c0c0c0;
  margin: 0 10px;
}

.assetsCounter {
  font-size: $font-size-s;
  color: #999999;
}

.contained_cell {
  margin-top: math.div($margin-m, 2);
  width: auto;
  background: $color-white;
  border: 1px solid $color-grey-600;
  box-sizing: border-box;
  border-radius: 6px;
  flex: 1;
  margin: 5px;
}

.containedHeader {
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: space-between;
  font-size: $font-size-m;
  font-weight: $font-weight-semi-bold;
  background-color: $color-grey-200;
  padding: 15px;
  border-bottom: 1px solid $color-grey-600;
}

.headerTitle {
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
}

.filtersWrapper {
  display: flex;
  align-items: center;
}

.filterLabel {
  font-size: $font-size-xs;
  padding-right: $padding-xs;
}

.wrapper {
  width: fit-content;
  height: 38px;

  .date_picker_input {
    height: 38px;
  }
}

.filterInput {
  width: 65px;
  height: 38px;
  border: 1px solid $color-grey-60;
  border-radius: 6px;
  margin-right: $margin-xs;
  padding: $padding-xs;
  font-size: $font-size-s;
  color: $color-input-txt;

  &:focus {
    outline: none;
    border-color: $color-cta-orange;
  }
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: $color-grey-600;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $font-size-xs;
  margin-left: 5px;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footContentItem {
  padding: 25px;
  display: flex;
  align-items: center;
}

.callAction {
  color: $color-link;
  margin-right: 5px;
}

.icon {
  width: 25px;
  height: 25px;
  margin-right: 20px;
}
