@import "styles/_variables.scss";

.container {
  display: flex;
  align-items: center;
  margin-bottom: $margin-xs;
}

.dot {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  border-radius: 50%;
  border: 5px solid $color-touched;
  margin-right: $margin-xs;
}

.dot_CRITICAL {
  @extend .dot;
  border-color: $asset-1;
}
.dot_HIGH {
  @extend .dot;
  border-color: $asset-2;
}
.dot_MEDIUM {
  @extend .dot;
  border-color: $asset-3;
}
.dot_LOW {
  @extend .dot;
  border-color: $asset-4;
}
.dot_NONE {
  @extend .dot;
  border-color: $asset-5;
}

.deleteButton {
  margin-left: $margin-s;
  cursor: pointer;
}
