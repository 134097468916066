@use 'sass:math';
@import "styles/_variables.scss";

.title {
  font-weight: $font-weight-medium;
  font-size: $font-size-2xl;
  line-height: 28px;
  color: $color-input-txt;
  margin-right: $margin-xl;
}

.row_tab {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-grey-450;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-left: math.div($margin-s, 2);
}

.number {
  font-size: $font-size-2xs;
  line-height: 14px;
  text-align: center;
  color: $color-gray-150;
}
