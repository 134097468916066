@import "styles/_variables.scss";

.input {
  font-size: $font-size-xs;
  line-height: 16px;
  font-weight: $font-weight-medium;
  color: $color-input-txt;
  border: none;
}

.input:disabled {
  background: transparent;
}
