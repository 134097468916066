@use 'sass:math';
@import "styles/_variables.scss";

.button_section {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: $margin-l $margin-xl $margin-l 0;
}

.btn_separator {
  margin-left: math.div($margin-xl, 2);
}

.dropdown {
  margin-bottom: $margin-xl;
}

.red {
  color: $color-error;
}

.txt1 {
  font-weight: $font-weight-regular;
  font-size: $font-size-xs;
  line-height: 16px;
  color: $color-input-txt;
  color: $color-cta-orange;
  margin: math.div($margin-m, 2) 0px 0px 0px;
}
