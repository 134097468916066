@use 'sass:math';
@import "styles/_variables.scss";

.button_section {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: $margin-l $margin-xl $margin-l 0;
}

.btn_separator {
  margin-right: math.div($margin-m, 2);
}

.delete_txt {
  margin-top: $margin-2xl;
  font-size: $font-size-xs;
  line-height: 22px;
  color: $color-input-txt;
}

.spacing_txt {
  margin-top: $margin-s;
}

.message {
  width: 100%;
  height: fit-content;
  font-size: $font-size-xs;
  color: $color-cta-bl-dark;
  padding: $padding-s 0;
}
